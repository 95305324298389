import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import { Col, Nav, Row, Tab, Form, Button, Navbar, Offcanvas, Container } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assests/images/logo.png';
import { FaYoutube, FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";


function Footer() {

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);

    return (

        <>
            <div className={`${cascading.footer} mt-3`}>
                <Container>
                    <Row className={`justify-content-between ${cascading.footerrow}`}>
                        <Col md={4} lg={3} className='mt-3'>
                        <Link to="/"><img src={logo} className={`img-fluid ${cascading.headerlogo}`} /></Link>
                            <p className={`${cascading.whtclr} mt-3 mb-0`}>Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit.</p>
                        </Col>
                        <Col md={8} lg={6} className='mt-3'>
                            <Row>
                                {/* <Col md={4} className='mt-3'>
                                    <h6>Lorem Ipsum</h6>
                                    <ul>
                                        <li><Link to="/">Rhoncus massa</Link></li>
                                        <li><Link to="/">Lacinia ornare</Link></li>
                                        <li><Link to="/">Pulvinar egestas</Link></li>
                                    </ul>
                                </Col> */}
                                <Col md={6} className='mt-3'>
                                    <h6>Company</h6>
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/aboutus">About Us</Link></li>
                                    </ul>
                                </Col>
                                <Col md={6} className='mt-3'>
                                    <h6>Support</h6>
                                    <ul>
                                        <li><Link to="/terms">Terms & Conditions</Link></li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className={`d-block d-sm-flex justify-content-between text-center mb-3 mt-3`}>
                        <p className={`${cascading.copyright} mt-3 mt-sm-0 mb-0`}>All Rights reserved@Sullam.com</p>
                        <div className={`${cascading.socialmedia} mt-3 mt-sm-0`}>
                            <a href='https://youtube.com/' target='_blank'><FaYoutube /></a>
                            <a href='https://www.instagram.com/' target='_blank'><FaInstagram/></a>
                            <a href='https://www.facebook.com/' target='_blank'><FaFacebookF/></a>
                        </div>
                    </div>
                </Container>
            </div>
        </>

    )
}

export default Footer