import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import cascading from '../assests/css/all.module.css';
import cascading1 from '../assests/css/settingcard.module.css';
import { addsubcription } from '../api/user.axios';
import toast from 'react-hot-toast'
function Buymodal(props) {

    const [buymodals, setBuymodals] = useState(true);

const {plans ,  user }= props
console.log("sssss",props)


    const chooseplan = async(data)=>{
      const id =   toast.loading("purchasing...")

let senddata={
    userid : user?.userid ,
    transactionid : "demotransactionid",
    planid : data?._id
}


const response = await addsubcription(senddata)
toast.dismiss(id)

if(response?.status){
    toast.success(response?.message)
}else{
    toast.error(response?.message)
}
    }

    return (

        <>

            <div className={`${cascading1.buymodallist}`}>
                  <Modal
                    className="walletmodal"
                    onHide={props.onDismiss}
                    show={buymodals}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
                    <Modal.Body className={`${cascading1.modalbody} pb-5`}>
                        <h4 className={`${cascading.titlesss} text-center`}>Choose your plan</h4>
                        
                        <div className='row justify-content-center'>

                            {plans?.map((data)=>       
                            <div className={`${cascading1.cardfull} col-sm-10 col-md-9 col-lg-6 col-xl-4 mt-4`}>
                                <div className={`${cascading1.settingscard}`}>
                                    <h5 className={`${cascading.whtclr}`}>{data?.planname}</h5>
                                    <p className={`${cascading.porttext}`}>Reward ${data?.reward}</p>
                                    <p className={`${cascading.porttext} mt-2`}>For individuals getting started
                                        with portfolio management</p>
                                    <h3 className={`${cascading.permonth} mt-4`}>${data?.planprice} for {data?.planperiod} Days </h3>
                                    {/* <p className={`${cascading.porttext}`}>with annual payment</p> */}
                                    <Button className={`${cascading.whtclr} ${cascading.themebtn} mt-3`} onClick={()=>chooseplan(data)}>Choose Plan</Button>
                                </div>
                            </div>)
}


                            {/* <div className={`${cascading1.cardfull} col-sm-10 col-md-9 col-lg-6 col-xl-4 mt-4`}>
                                <div className={`${cascading1.settingscard}`}>
                                    <h5 className={`${cascading.whtclr}`}>Plus</h5>
                                    <p className={`${cascading.porttext}`}>Portfolio: up to $10K</p>
                                    <p className={`${cascading.porttext} mt-2`}>For advanced individuals and
                                        professionals</p>
                                    <h3 className={`${cascading.permonth} mt-4`}>$25 per month</h3>
                                    <p className={`${cascading.porttext}`}>with annual payment</p>
                                    <Button className={`${cascading.whtclr} ${cascading.themebtn} mt-3`}>Choose Plan</Button>
                                </div>
                            </div>
                            <div className={`${cascading1.cardfull} col-sm-10 col-md-9 col-lg-6 col-xl-4 mt-4`}>
                                <div className={`${cascading1.settingscard}`}>
                                    <h5 className={`${cascading.whtclr}`}>Pro</h5>
                                    <p className={`${cascading.porttext}`}>Portfolio: $10K+</p>
                                    <p className={`${cascading.porttext} mt-2`}>For seasoned players and
                                        corporations</p>
                                    <h3 className={`${cascading.permonth} mt-4`}>$50</h3>
                                    <p className={`${cascading.porttext}`}>annual deposit</p>
                                    <Button className={`${cascading.whtclr} ${cascading.themebtn} mt-3`}>Choose Plan</Button>
                                </div>
                            </div> */}
                        </div>
                    </Modal.Body>
                </Modal>

            </div>

        </>
    )
}

export default Buymodal;