import React,{useState, useEffect} from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import Sidebar from '../common/Sidebar';
import { Col, Nav, Row, Tab, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Forgetpasswordfetch } from '../api/user.axios';
import { toast } from 'react-hot-toast';
import { Newpassword } from './changepassword';
function Forgot() {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);
const [email , setemail]=useState("")
const [success , setsuccess]= useState(true)

      const updatedata=(e)=>{
        setemail(e.target.value)
        console.log(email)
      }


const submit = async()=>{
const id = toast.loading("sending otp")
let data ={
    email : email
}
    let response = await Forgetpasswordfetch(data)

    toast.dismiss(id)
    if(response?.status){
    toast.success(response?.message)
    setsuccess(false)
    }else{
    toast.error(response?.message)
    }

}

    return (success ? 
        <div className={`${cascading.loginborder}`}>
            <div className={`container ${cascading.custom_container}`}>
                <Row>
                    <Col sm={9} md={10} xl={8} className={`mx-auto`}>
                        <div className={`${cascading.logincard}`}>
                            <h2 className={`${cascading.whtclr} mb-4`}>Forgot Password</h2>
                            <Row>
                                <Col md={12} className={`mx-auto`}>
                                    <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                        <Form.Label className={`${cascading.whtclr}`}>Email address</Form.Label>
                                        <Form.Control className={`${cascading.inputfiled}`} onChange={updatedata} type="email" placeholder="Enter your Email address" />
                                    </Form.Group>
                                </Col>
                                <div className={`d-flex justify-content-between mt-2`}>
                                    <Link to="/login" ><Button className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-4`}>Back</Button></Link>
                                    <Button className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-4`} onClick={submit}>Submit</Button>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        </div> : <Newpassword email={email}/>
    )
}

export default Forgot