import cascading from "../assests/css/all.module.css";
import { Col, Nav, Row, Tab, Form, Button, Toast } from "react-bootstrap";
import { useState } from "react";
import { verifyotp , resendotp} from "../api/user.axios";
import toast  from "react-hot-toast";
import { useNavigate } from "react-router";
export const Veriftyotp = (props)=>{
    const { email }= props
const [otp, setotp] = useState()
const navigate = useNavigate()
const updatedata = (e)=>{
    console.log(e.target.value)
    setotp(e.target.value)
}

const [process , setprocess]=useState(false)
const verifycall=async()=>{
    if(!otp){
        toast.error("otp can't be empty")
        return 
    }

    let senddata ={
        otp : otp,
        email : email
    }
    console.log(otp , email)
    const resultotp = await verifyotp(senddata)
    console.log("resultopt" , resultotp)
    if(resultotp?.status){
         toast.success(resultotp?.message);
         navigate('/')
    }else{
        toast.error(resultotp?.message);
    }
    
    
      }

const  resendotpfunction = async()=>{
  const id = toast.loading("Sending OTP")
    let senddata ={
        email : email
    }
    const resendotpresponse = await resendotp(senddata)
    console.log("resendotpresponseresendotpresponse" , resendotpresponse)
    toast.dismiss(id)
    if(resendotpresponse?.message.includes('succesfully')){
         toast.success(resendotpresponse?.message);
        //  navigate('/login')
    }else{
        toast.success(resendotpresponse?.message);
    }
}


    return (
        <div className={`${cascading.loginborder}`}>
          <div className={`container ${cascading.custom_container}`}>
            <Row>
              <Col sm={9} md={10} xl={8} className={`mx-auto`}>
                {/* <div className={`${cascading.logincard}`}> */}
                  <h2 className={`${cascading.whtclr} mb-4`}>Verification</h2>
                  <p className={`${cascading.whtclr} mb-4`}>Email send to <span className={`${cascading.grclr} mb-4`}>{email}</span>. please check your indox and spam </p>
              
                   <div>
                    <Col md={6}>
                      <Form.Group className="mb-4">
                        <Form.Label className={`${cascading.whtclr}`}>
                          OTP
                        </Form.Label>
                        <Form.Control
                          className={`${cascading.inputfiled}`}
                          type="Number"
                          id="otp"
                          onChange={updatedata}
                        //   value={}
                          placeholder="Enter your OTP"
                        />
                         
                      
                      </Form.Group>
                  
                    </Col>
                    <Button
                        className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-4`}
                        onClick={resendotpfunction}
                       
                      >
                        Resend OTP
                      </Button>
                    <Button
                        className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-4 ms-3`}
                        onClick={verifycall}
                        disabled={process}
                       
                      >
                        Verifty OTP
                      </Button>
                      </div>
                {/* </div> */}
              </Col>
            </Row>
          </div>
        </div>
      );
}