import React, { useEffect, useState } from "react";
import cascading from "../assests/css/all.module.css";
import Grid from "@mui/material/Grid";
import Sidebar from "../common/Sidebar";
import { Col, Nav, Row, Tab, Form, Button } from "react-bootstrap";
import { Link  } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { loginfetch, TokenUpdate } from "../api/user.axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

import { useDispatch } from "react-redux";
import Twofamodal from "../components/twofamodal";
import { Veriftyotp } from "./verifyotp";
function Login() {
  const [verify , setverify]=useState(false)
const dispatch = useDispatch()
    const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
const initialdata ={
    email : "",
    password   : "" ,
    savesetting : false,
}

const phoneinitialdata ={
    phonenumber : "" ,
    password : "",
    savesetting : false,
}


const [formdata , setformdata ]= useState(initialdata)
const [phoneformdata , phonesetformdata ]= useState(phoneinitialdata)

const updatedata = (e) =>{
    // e.preventDefault()

    
const {id , value , checked} = e?.target
if(id == "default-checkbox"){
    setformdata({...formdata , [id] : checked})
}else{
    setformdata({...formdata , [id] : value})
}

}

const phonedata = (e)=>{
    phonesetformdata({...phoneformdata , "phonenumber" : e})
}

const phoneupdatedata = (e)=>{
    const {id , value , checked} = e?.target 
if(id == "default-checkbox"){
    phonesetformdata({...phoneformdata , [id] : checked})
}else{
    phonesetformdata({...phoneformdata , [id] : value})
}
}
const connect = async (type)=>{
  dispatch({
    type : "userdata" , 
    data : { userdata: ""}
})
  localStorage.clear()
  sessionStorage.clear()
const toastid = toast.loading("login in please wait ")
let data 
if(type === "phone"){
data =  phoneformdata
}

if(type === "email"){
data =  formdata 
}
console.log("shshshshs" , data)

data.type = type 

const response = await loginfetch(data)
toast.dismiss(toastid)

if(response?.status){
  
toast.success(response?.message)
dispatch({
    type : "userdata" , 
    data : { userdata: response?.data}
})
if(data?.savesetting){
 
    localStorage.setItem("usertoken", response?.token);
}else{
  
    sessionStorage.setItem("usertoken", response?.token);
}
TokenUpdate()
navigate('/multiarbi')
}else{

if(response?.message?.includes('succesfully')){
  toast.success('OTP send to your Email please verify otp ')
  setverify(true)
  return 
}
  if(response?.message === "twofa"){
  setTwofamodal(true)
  return 
  }
  
    toast.error(response?.message)
}

}

const[twofamodal, setTwofamodal] = useState(false)
const [phraseshow, setPhraseyshow] = useState(false)

  return (<>
    {!verify ?
    <> 

   
    {twofamodal && <Twofamodal userdata={formdata} onDismiss={() => setTwofamodal(false)}/> }
    <div className={`${cascading.loginborder}`}>
      <div className={`container ${cascading.custom_container}`}>
        <Row>
          <Col sm={9} md={10} xl={8} className={`mx-auto`}>
            <div className={`${cascading.logincard}`}>
              <h2 className={`${cascading.whtclr} mb-4`}>Login</h2>

              <div className={`${cascading.loginsection}`}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="emails">
                  <Row className="mb-5">
                    <Col sm={12}>
                      <Nav
                        variant="pills"
                        className={`flex-row justify-content-center mb-2 ${cascading.navstyle}`}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="emails">Email</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="mobiles">Mobile</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content className="mt-3">
                        <Tab.Pane eventKey="emails">
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className={`${cascading.whtclr}`}>
                                Email address
                              </Form.Label>
                              <Form.Control
                                className={`${cascading.inputfiled}`}
                                id = "email"
                                type="email"
                                placeholder="Enter registered Email ID"
                                onChange={(e)=>updatedata(e)}
                              />
                            </Form.Group>
                            {/* <div className={`input-group ${cascading.inputstyle}`}> */}
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className={`${cascading.whtclr}`}>
                                Password
                              </Form.Label>
                              <div className="d-flex">
                              <Form.Control
                                className={`${cascading.inputfiled } ml-0`}
                                id="password"
                                type={!phraseshow ? "password" : "text"}
                                placeholder="Enter your Password"
                                onChange={(e)=>updatedata(e)}
                              ></Form.Control>
                                <div className={`input-group-append`} onClick={() => setPhraseyshow(!phraseshow)}>
                                    <span className={`input-group-text ${cascading.h40 }`} >{phraseshow ? <FaRegEye fill='#fff' /> : <FaRegEyeSlash fill='#fff' />}</span>
                                </div>
                                </div>
                            </Form.Group>
                           
                                {/* </div> */}
                            <Form.Check
                              type="checkbox"
                              id={`savesetting`}
                              label={`Keep me signed in on this computer`}
                              onChange={(e)=>updatedata(e)}
                            />
                            <div className={`text-center mt-3`}>
                              {/* <Link to="/multiarbi"> */}
                                <Button
                                  className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-5`}
                                  onClick={()=>connect("email")}
                                >
                                  Login
                                </Button>
                              {/* </Link> */}
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                              <Link
                                className={`${cascading.lightyclr}`}
                                to="/forgotpassword"
                              >
                                Forgot Password?
                              </Link>
                              <Link
                                className={`${cascading.lightyclr}`}
                                to="/register"
                              >
                                Sign up for free
                              </Link>
                            </div>
                          </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="mobiles">
                          <Form>
                            <div className="mt-3 mb-3">
                              <Form.Label className={`${cascading.whtclr}`}>
                                Mobile Number
                              </Form.Label>
                              <PhoneInput
                                country={"us"}
                                // placeholder='Enter registered Mobile number'

                                // value={phone}
                                // onChange={phone}
                                id="phone"
                                onChange={(e)=>phonedata(e)}
                              />
                            </div>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className={`${cascading.whtclr}`}>
                                Password
                              </Form.Label>
                              <div className="d-flex">
                              <Form.Control
                                className={`${cascading.inputfiled}`}
                                id="password"
                                type={!phraseshow ? "password" : "text"}
                                placeholder="Enter your Password"
                                onChange={(e)=>phoneupdatedata(e)}
                              ></Form.Control>
                                  <div className={`input-group-append`} onClick={() => setPhraseyshow(!phraseshow)}>
                                    <span className={`input-group-text ${cascading.h40 }`} >{phraseshow ? <FaRegEye fill='#fff' /> : <FaRegEyeSlash fill='#fff' />}</span>
                                </div></div>
                            </Form.Group>
                           
                            <Form.Check
                              type="checkbox"
                              id={`default-checkbox`}
                              label={`Keep me signed in on this computer`}
                              onChange={(e)=>phoneupdatedata(e)}
                            />
                            <div className={`text-center mt-3`}>
                              {/* <Link to="/multiarbi"> */}
                                <Button
                                  className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-5`}
                                  onClick={()=>connect("phone")}
                                >
                                  Login
                                </Button>
                              {/* </Link> */}
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                              <Link
                                className={`${cascading.lightyclr}`}
                                to="/forgotpassword"
                              >
                                Forgot Password?
                              </Link>
                              <Link
                                className={`${cascading.lightyclr}`}
                                to="/register"
                              >
                                Sign up for free
                              </Link>
                            </div>
                          </Form>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div> 
    </> :  <Veriftyotp email={formdata?.email}/> }</>
  );
}

export default Login;
