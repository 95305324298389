import React from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import Sidebar from '../common/Sidebar'
function Arbitrages() {
    return (
        <div className={`${cascading.innerpages}`}>
           
            <Grid container >
                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                    <Sidebar />
                </Grid>
                <Grid item xl={10} lg={10} md={12} sm={12} xs={12} >
                  <p className={`${cascading.maintitle} pt-5`}>API Binding</p>
                </Grid>
            </Grid>
        </div>
    )
}

export default Arbitrages