import React, { useState , useEffect } from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import { Col, Nav, Row, Tab, Form, Button, Navbar, Offcanvas, Container } from 'react-bootstrap';
import { Link, NavLink , useNavigate} from 'react-router-dom';
import logo from '../assests/images/logo.png';
import { useSelector , useDispatch } from 'react-redux';
import { fetchreconnect } from '../api/user.axios';
import { toast } from 'react-hot-toast';
import Logoutmodal from './logoutmodal';
import Walletmodal from '../components/walletmodel';
import { initialConnectWallet } from '../hook/useWallet';
import { isEmpty } from '../actions/common';

function Header() {

    const userdata = useSelector(state => state?.redux?.userdata)
    const account = useSelector(state => state?.redux?.AccountDetails)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [menuOpen, setMenuOpen] = useState(false);  // initially closed

    const toggleMenu = () => {  
  // alert(1)
      const getWindowWidth= window.innerWidth
      console.log("windn",getWindowWidth, menuOpen)
      if(getWindowWidth<992){
        
        setMenuOpen(false)
      }else{setMenuOpen(false)}
      
      // this handler is "regular"
        // setMenuOpen(!menuOpen);    // open and close...
    };


useEffect(()=>{
    const token = localStorage.getItem('usertoken') ?  localStorage.getItem('usertoken') : sessionStorage.getItem('usertoken')
    if(token){
      fetchdata()
    }
    if (localStorage.getItem("walletConnectType")) {
        handleAccountChange()
    }
},[])

useEffect(() => {
    if (window.ethereum) {
        window.ethereum?.on("accountsChanged", handleAccountChange);
        window.ethereum.on("chainChanged", handleAccountChange);
        return () => {
            window.ethereum?.removeListener("accountsChanged", handleAccountChange);
            window.ethereum?.removeListener("chainChanged", handleAccountChange);

        };
    }
}, [])

const handleAccountChange = async(data) => {
    // const id = toast.success("Wallet Connecting...", { autoClose: 1000, isLoading: false })
    // toast.update(id, { autoClose: 1000, isLoading: false, })

   let res = await initialConnectWallet(localStorage.getItem("walletConnectType"));
   dispatch({
    type: "Account_Section",
    data: { AccountDetails: res },
  });

}


async function fetchdata(){
    const res = await fetchreconnect()
    console.log("fetchdatafetchdatafetchdata",res?.status)  
if(res?.status){
    dispatch({
        type : "userdata" , 
        data : { userdata: res?.data}
    })
}else{
    if(res?.message){
            dispatch({
              type : "userdata" , 
              data : { userdata: ""}
            })
            navigate('/')
}
}
}


const disconnect = ()=>{
    localStorage.removeItem('walletConnectType')
    localStorage.removeItem('accountInfo')
    sessionStorage.clear()
    dispatch({
        type: "Account_Section",
        data: { AccountDetails: {} },
      });
      toast.success("Disconneced Successfully")
}

const [logshow, setLogshow] = useState(false)
const [wallet, setWallet] = useState(false)

    return (

        <>

{logshow && <Logoutmodal onDismiss={() => setLogshow(false)} />}
{wallet && <Walletmodal onDismiss={() => setWallet(false)} isOpen={wallet}/>}
{/* {buymodals && <Buymodalpage plans={plandata} user={userdata} onDismiss={() => setBuymodals(false)} />} */}

            <Navbar key="lg" expand="lg" >
                <Container>
                    <Navbar.Brand href="/"><img src={logo} className={`img-fluid ${cascading.headerlogo}`} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`}  onClick={()=>setMenuOpen(!menuOpen)}/>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-lg`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                        placement="end"
                        restoreFocus={false}
                        show={menuOpen}
                        onHide={()=>setMenuOpen(!menuOpen)}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                <img src={logo} className={`img-fluid ${cascading.headerlogo} ${cascading.mobilelogo}`} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className={`justify-content-end flex-grow-1 align-items-center pe-3 ${cascading.navlist}`}>
                              <NavLink className={`${cascading.nava}`} to="/" onClick={()=>toggleMenu()}>Home</NavLink>
                              <NavLink className={`${cascading.nava}`} to="/contact" onClick={()=>toggleMenu()}>Contact</NavLink>
                              <NavLink className={`${cascading.nava}`} to="/terms" onClick={()=>toggleMenu()}>Terms</NavLink>
                              {userdata?.email &&   <NavLink className={`${cascading.nava} mb-3 mb-lg-0`} to="/multiarbi" onClick={()=>toggleMenu()}>Dashboard</NavLink>}
                            
                            {!userdata?.email ? (<>
                              <Link to="/login"><Button className={`btn ${cascading.headerborderbtn} ms-2`}>Login</Button></Link>
                              <Link to="/register"><Button className={`btn ${cascading.headerbtn} ms-3`}>Register</Button></Link>
                               </>): <>
                               <NavLink className={`${cascading.nava}`} 
                                 to="/pricing"
                            //    to="/terms" onClick={()=>toggleMenu()}
                               >Pricing</NavLink>
                                 <Link><Button className={`btn ${cascading.headerbtn} ms-3`} onClick={()=>{isEmpty(account) ? setWallet(true) : disconnect() }}>{isEmpty(account) ? `Connect Wallet` : `Disconnect`}</Button></Link>
                               <Button className={`btn ${cascading.headerborderbtn} ms-2`} onClick={()=>setLogshow(true)}>Logout</Button>
                               </>
                                      }          </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>



        </>

    )
}

export default Header