import React from 'react'
import cascading from '../assests/css/settingcard.module.css'
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { fetchgettwofa } from '../api/user.axios';
function Settingcard(props) {

 
  console.log(props, "propspropsprops")
  return (
    <div className={`${cascading.settingscard}`}>
      <h6 className={`${cascading.whtclr}`}>{props.datas.title}</h6>
      <div className='d-sm-flex justify-content-between align-items-center'>
        <div className={`${cascading.width70} mt-3 mt-sm-0`}>
            <span className='d-flex align-items-baseline mt-2'><img src={props.datas.image} className={`img-fluid`}/>
            <div><p className={`${cascading.whtclr} ${cascading.f12} ms-2 mb-0`}>{props.datas.minititle}</p><p className={`${cascading.grayclr} ${cascading.f12} ms-2 mb-0`}>{props.datas.desc}</p></div>
            </span> 
        </div>
        <div className='ms-2 mt-3 mt-sm-0'>
           {/* <Link to="#"> */}
           {props.datas.btnname != "verified"&& 
            <Button className={`${cascading.whtclr} ${cascading.themebtn}`} onClick={
           async ()=> {
              if(props?.datas?.btnname === "Enable 2FA"){
               
                let data = await fetchgettwofa()
                console.log("datadatadata",data?.data)
                props.qr(data) 
              }
              
              props.popfunction(props.datas.btnname)
            }
           } >{props.datas.btnname}</Button>}
           {/* </Link> */}
        </div>
      </div>

    </div>
  )
}

export default Settingcard