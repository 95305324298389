import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import cascading from '../assests/css/all.module.css';
import cascading1 from '../assests/css/settingcard.module.css';
import OtpInput from 'react-otp-input';
import { fetchconnecttwofa, TokenUpdate } from '../api/user.axios';
import toast from 'react-hot-toast'
import { useDispatch } from "react-redux";
import * as yup from 'yup';
import { useNavigate } from 'react-router';

function Twofamodal(props) {

    const navigate = useNavigate()
    const { email , password , savesetting } = props?.userdata
    const [propdata , setpropdata] = useState(props?.userdata)
    const[twofamodal, setTwofamodal] = useState(true)
    const [otp, setOtp] = useState('');
    const[process , Setprocess]=useState(false)
    const dispatch = useDispatch()
    const [Errors,setErrors]= useState({})
   
    useEffect(()=>{
        setpropdata(props?.userdata)
    },[props])

const connecttwofa = async()=>{
    Setprocess(true)
    try{
        let data = { 
            "email" : email , 
            "twofacode" : otp 
        }
        const loginSchema = yup.object({
            email: yup.string().email('Please enter a valid email address').required('Email is required'),
            twofacode : yup.string().matches(/^\d{6,}$/, 'OTP must be at least 6 digits').required('OTP is required'),
          });


          const isValid = await loginSchema.validate(data , {abortEarly: false});


        const response = await fetchconnecttwofa(data)
        console.log("responseresponseresponse", response)
        if(response?.status){
            sessionStorage.clear()
            localStorage.clear()

            toast.success(response?.message)
        
            dispatch({
                type : "userdata" , 
                data : { userdata: response?.data}
            })
            console.log("propsprops" , propdata)
            if(propdata?.savesetting){
                  localStorage.setItem("usertoken", response?.token);
              }else{
               
                  sessionStorage.setItem("usertoken", response?.token);
              }
              TokenUpdate()

              navigate('/')
        }else{
            toast.error(response?.message)
        
        }


    }catch(error){
    toast.error("validation error")
    const newErrors = {};
    error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);


    }finally{
        Setprocess(false)
    }





}
console.error(Errors)

    return (

        <>

        <div>
        <div className={`${cascading1.buymodallist}`}>
        <Modal
                    className="walletmodal"
                    onHide={props.onDismiss}
                    show={twofamodal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
        <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
        <Modal.Body className={`${cascading1.modalbody} ${cascading1.otpmodals} text-center  pb-5`}>
                        <h4 className={`${cascading.titlesss} text-center mb-4`}>Enter 2FA Code</h4>
                        <OtpInput                
      value={otp}
      onChange={(e)=>{
        setOtp(e)
        setErrors({})
    }}
      numInputs={6}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
    {Errors?.twofacode && <span class="text-danger mb-2">{Errors?.twofacode}</span>}


<Button className={`py-1 px-3 mt-4 ${cascading.whtclr} ${cascading.themebtn}`} disabled={process} onClick={connecttwofa}>Submit</Button>

</Modal.Body>
                    </Modal>


            </div>


        </div>
       


        </>
    )
}

export default Twofamodal;