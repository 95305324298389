import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import cascading from '../assests/css/all.module.css';
import cascading1 from '../assests/css/settingcard.module.css';
import { Link, NavLink , useNavigate} from 'react-router-dom';
import { useSelector , useDispatch } from 'react-redux';


function Logoutmodal(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
     const [logshow, setLogshow] = useState(true)
     const disconnect = ()=>{
        localStorage.clear()
        sessionStorage.clear()
        props.onDismiss()
        dispatch({
            type : "userdata" , 
            data : { userdata: ""}
        })
        navigate('/')
        
    }

    return(

<>

<div className={`${cascading1.buymodallist}`}>
                  <Modal
                    className="walletmodal"
                    onHide={props.onDismiss}
                    show={logshow}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
                    <Modal.Body className={`${cascading1.modalbody} pb-5`}>
                    <h4 className={`${cascading.titlesss} text-center`}>Oh no! You're leaving...</h4>
                    <h4 className={`${cascading.titlesss} text-center`}>Are you sure?</h4>
                    <div className='d-flex align-items-center justify-content-center mt-4'>
                    <Button className={`py-2 px-3 ${cascading.whtclr} ${cascading.themebtn}`} onClick={disconnect}>Yes</Button>
                    <Button  className={`py-2 px-3 ${cascading.whtclr} ${cascading.themebtn} ms-3`} onClick={props.onDismiss}>No</Button>
                    </div>
                    </Modal.Body>
                </Modal>

            </div>



</>




    )
}
export default  Logoutmodal
    
