import { combineReducers } from "redux"

const Initial_State = {
    userdata: {},
    oppetinuties: [],
    AccountDetails:{}
}


function redux(state = Initial_State, action) {

    switch (action.type) {

        case "userdata":
            return {
                ...state, ...action.data
            }
        case "Arbitrages":
            return {
                ...state, ...action.data
            }
        case "Account_Section":
            return {
                ...state, ...action.data
            }
        default:
            return state
    }
}

const sullam = combineReducers({ redux: redux })

export default sullam;