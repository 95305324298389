import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css';
import Grid from '@mui/material/Grid';
import { Col, Nav, Row, Tab, Form, Button, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../common/header';
import Footer from '../common/footer';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toast  from "react-hot-toast";
import * as yup from 'yup'
import {fetchuploadcontactus } from '../api/cms.axios'
function Contact() {
    const init ={
        name : "",
        phonenumber:"",
        email : "",
        description : ""
    }
    const [errorvalue, seterror] = useState({});
    const [formData , setformData]=useState(init)
    const [phone , setphone ]= useState("")
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

const updater= (e)=>{
    seterror({})
    const {id , value } = e.target
setformData({...formData , [id] : value})
}

const sumbitdata = async()=>{
try{
const payload = {
    email : formData.email , 
    phonenumber : phone , 
    name : formData.name , 
    description: formData.description
}

const contactusschema = yup.object({

name :  yup.string().required("name can't be empty"),
email : yup.string().email('Enter valid email address').required('Email is required'),
phonenumber :   yup.string().matches(/^(\+)?[0-9\s-]{6,15}$/, 'Enter valid phone number'),
description : yup.string().required("description can't be empty"),
})

await  contactusschema.validate(payload, { abortEarly: false })

const res = await fetchuploadcontactus(payload)
if(res.status){
    toast.success(res.message)
    setformData(init)
    setphone('')
}

}catch(error){
    toast.error("please enter valid details");
  console.error(error)
    const newErrors = {};
    error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      seterror(newErrors);
}
}



    return (

        <>
            <Header />
            <div className='pt-5 pb-5'>
                <div className={`container pt-3 pb-3`}>
                    {/* <div className={`d-flex ${cascading.h100}`}>
                    <h2 className={`${cascading.liness}`}>Coming Soon..</h2>
                </div> */}
                    <div className='row'>
                        <div className='col-lg-9 col-xxl-9 mx-auto'>
                            <div className={`${cascading.cardstyle} ${cascading.accountcard} px-3 px-sm-5`}>
                                <h1 className={`${cascading.firsth1} mb-5 text-center`} style={{ color: "#C9FF69" }}>Contact us</h1>

                                <Form>
                                    <Form.Group
                                        className="mb-4"
                                    >
                                        <Form.Label className={`${cascading.whtclr}`}>
                                            Name
                                        </Form.Label>
                                        <Form.Control
                                            className={`${cascading.inputfiled}`}
                                            id="name"
                                            onChange={updater}
                                            type="text"
                                         value={formData.name}

                                            placeholder="Name"
                                        />
                                        {errorvalue?.name &&  <span class="text-danger">{errorvalue?.name}</span> }
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-4"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label className={`${cascading.whtclr}`}>
                                            Email address
                                        </Form.Label>
                                        <Form.Control
                                            className={`${cascading.inputfiled}`}
                                            id="email"
                                            value={formData.email}
                                            onChange={updater}
                                            type="email"
                                            placeholder="Enter Your Email ID"
                                        />
                                            {errorvalue?.email &&  <span class="text-danger">{errorvalue?.email}</span> }
                                    </Form.Group>
                                    <div className="mb-4">
                                        <Form.Label className={`${cascading.whtclr}`}>
                                            Mobile Number
                                        </Form.Label>
                                        <PhoneInput
                                            country={"us"}
                                            value={phone}
                                            onChange={(e)=> setphone(e)}
                                            placeholder='Enter your Mobile number'
                                            id="phone"
                                        />
                                         {errorvalue?.phonenumber &&  <span class="text-danger">{errorvalue?.phonenumber}</span> }
                                    </div>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label className={`${cascading.whtclr}`}>
                                            Description
                                        </Form.Label>
                                        <Form.Control as="textarea"
                                         onChange={updater}
                                         value={formData.description}
                                         id="description"
                                        rows={3} />
                                         {errorvalue?.description &&  <span class="text-danger">{errorvalue?.description}</span> }

                                    </Form.Group>
                                </Form>
                                <div className='mt-4 mb-4 text-center'>
                                    <Button className={`py-2 px-3 ${cascading.whtclr} ${cascading.themebtn}`}
                                    onClick={sumbitdata}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />


        </>

    )
}

export default Contact  