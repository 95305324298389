import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Col, Nav, Row, Tab, ButtonGroup, Button, InputGroup, Form, Table } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import cascading from '../assests/css/all.module.css';



export default function DataTables(props) {
    const { tradedata } = props
   

    const rows = [];

    for (let i = 0; i < tradedata?.length; i++) {
        let btname = tradedata[i]?.type.split("_")
        let currencies = `${tradedata[i]?.pair}/USDT`.split('/')
        let data = {
          id: i + 1,
          pair: `${tradedata[i]?.pair}/USDT`,
          trade1: btname[0],
          trade2: btname[1],
          trade3: btname[2],
          pair1 : tradedata[0]?.pair,
          pair2 : tradedata[1]?.pair,
          pair3 : tradedata[2]?.pair,
          allamt : `${tradedata?.[i]?.trade?.[0]?.spend} ${currencies[0]}/${tradedata?.[i]?.trade?.[1]?.spend} ${currencies[1]}/${tradedata?.[i]?.trade?.[2]?.spend} ${currencies[2]}/${tradedata?.[i]?.trade?.[2]?.buy} ${currencies[3]}`,
          exchange: tradedata[i]?.exchange,
          revenue: `${Number(tradedata?.[i]?.trade?.[2]?.buy) - Number(tradedata?.[i]?.trade?.[0]?.spend)}`//tradedata[i]?.revenue,
        };
        rows.push(data)
    }


    return (
        <>
        <div
        // style={{ height: 400, width: '100%' }}
        >

            <div className='alltables'>
                <Table responsive>
                    <thead>
                        <th>Pair</th>
                        <th>Trade</th>
                        <th>Invesment & Return</th>
                        {/* <th>Exchange</th> */}
                        <th>Revenue</th>
                    </thead>
                    {console.log("asdasgd"  ,rows )}
                    <tbody>
                    {rows.length > 0 ? <> {rows.map((data)=> <tr>
                      <td>{data.pair}</td>
                      <td>
                          <div>
                              {/* cascading.btnoutline  */}
                              <Button className={`${data.trade1 === "BUY" ? cascading.btnoutline : cascading.btnredoutline} me-2`}>{data.trade1}</Button>
                              <Button className={`${data.trade2 === "BUY" ? cascading.btnoutline : cascading.btnredoutline} me-2`}>{data.trade2}</Button>
                              <Button className={`${data.trade3 === "BUY" ? cascading.btnoutline : cascading.btnredoutline} me-2`}>{data.trade3}</Button>
                          </div>
                      </td>
                      <td>{data?.allamt}</td>
                      {/* <td>
                      {data.exchange}
                      </td> */}
                      <td>
                          <p className={`${cascading.greencolor}`}>   {data.revenue}    </p>
                      </td>
                  </tr>
                    
                    )}
              </>
                    : 
                    
                    
                    <tr key="data-empty"> 
                    <td colspan="4">No Data Found</td>
                     </tr>}
                      



                    </tbody>

                </Table>
           

            </div>


        </div>
    </>
    );
}