import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css';
import cascading1 from '../assests/css/settingcard.module.css';
import Grid from '@mui/material/Grid';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import Sidebar from '../common/Sidebar'
import { Link } from 'react-router-dom';
import Settingcard from '../components/settingcard';
import profileicon from '../assests/images/profileicon.png';
import { FaRegEdit } from "react-icons/fa";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { Col, Nav, Row, Tab, ButtonGroup, Button, Modal } from 'react-bootstrap';
import CopyToClipboard from "react-copy-to-clipboard";
import tool from "../assests/images/tool.svg";
import tick from "../assests/images/tick.svg";
import Buymodalpage from "../components/buymodal";
import { useSelector , useDispatch } from 'react-redux';
import Settingmodal from '../components/settingsmodal';
import { fetchreconnect ,fetchplanLists } from '../api/user.axios';
import toast  from 'react-hot-toast';
import config from '../config'
import { Encryptdata } from '../actions/encode_decode';
function Settings() {
    const reduxuserdata = useSelector(state => state?.redux.userdata)
    const [plandata, setplandata] = useState({})
const [twodata , settwodata] = useState()
const dispatch = useDispatch()


    const [userdata , setuserdata] = useState(reduxuserdata)

 

    async function fetchdata(){
        const res = await fetchreconnect()
        console.log("fetchreconnectfetchreconnect" , res)
      if(res?.status){
        setuserdata( res?.data)
        dispatch({
            type : "userdata" , 
            data : { userdata: res?.data}
        })
        SetAccountinfo(res?.data)
        SetAdvance(res?.data)
      }else{
        if(res?.message){
      toast.error(res?.message)}
      }
      }

    const fetchplans = async () => {
        const resp = await fetchplanLists();
        if (resp?.status) {
            setplandata(resp?.data);
        }
    }

   const SetAccountinfo = (userdata)=>{
    setAccountinfo([{
        title: "Profile Picture",
        minititle: userdata?.profile ? "change profile" : "Not yet configured",
        desc: userdata?.profile  ? 'change profile picture ':'Please upload a profile picture',
        image: userdata?.profile ? tick : tool,
    
        btnname: "Add Profile",
    
    },
    
    {
        // title: "Email Authentication",
        title: "Change Email",
        minititle: `${userdata?.email}`,
        desc: userdata?.registered ?'Verified email' : "need verification",
        image: userdata?.registered  ? tick : tool,
        btnname:  userdata?.registered ? "Change Email" : "Verify Email"
    },
    {
        title: "SMS Authentication",
        minititle: userdata?.phonenumberstatus ? "configured":"Not yet configured",
        desc:userdata?.phonenumberstatus  ? 'Verified Phonenumber' : "Verify your  Phonenumber",
        image: userdata?.phonenumberstatus ? tick :tool,
        btnname: userdata?.phonenumberstatus ? "verified": "verify phone number"
    },
    {
        title: "Password",
        minititle: "Settings",
        // desc:'Verified email',
        image: tick,
        btnname: "Change Password"
    }
    ])
   }
      
    const SetAdvance = (userdata)=>{
        handleOnChange()
        setAdvanced([{
            title: "Two Factor Authentication",
            minititle: userdata?.Google != "enable" ? "Not yet configured" : "configured",
            desc: userdata?.Google !== "enable" ? "Please enable Two Factor Authentication for added security." : "Two Factor Authentication is currently enabled for your account.",
            image: userdata?.Google != "enable" ? tool : tick,
            btnname: userdata?.Google != "enable" ? "Enable 2FA" : "verified"
        },
        {
            title: "Anti-Phishing Code",
            minititle: userdata?.anticode ? "configured" :"Not yet configured",
            desc: userdata?.anticode  ? 'anti-phishing code,it will appear in all official emails from Arbitribe tio prevent phishing attempts.':'After you have successfully set up the anti-phishing code,it will appear in all official emails from Arbitribe tio prevent phishing attempts.',
            image:  userdata?.anticode ?  tick : tool ,
            btnname: userdata?.anticode ? "Edit Code" :  "Add Code"
        },
        ])
    }

   const init = [{
    title: "Profile Picture",
    minititle: userdata?.profile ? "change profile" : "Not yet configured",
    desc: userdata?.profile ? 'edit profile picture ':'Please upload a profile picture',
    image: userdata?.profile ? tick : tool,

    btnname: "Add Profile",

},

{
    // title: "Email Authentication",
    title: "Change Email",
    minititle: `${userdata?.email}`,
    desc: userdata?.registered ?'Verified email' : "need verification",
    image: userdata?.registered  ? tick : tool,
    btnname:  userdata?.registered ? "Change Email" : "Verify Email"
},
{
    title: "SMS Authentication",
    minititle: userdata.phonenumberstatus ? "configured":"Not yet configured",
    desc:userdata.phonenumberstatus  ? 'Verified Phonenumber' : "Verify your  Phonenumber",
    image: userdata.phonenumberstatus ? tick :tool,
    btnname: userdata.phonenumberstatus ? "verified": "verify phone number"
},
{
    title: "Password",
    minititle: "Settings",
    // desc:'Verified email',
    image: tick,
    btnname: "Change Password"
}
]

const act = [{
    title: "Two Factor Authentication",
    minititle: userdata?.Google != "enable" ? "Not yet configured" : "configured",
    desc: userdata?.Google !== "enable" ? "Please enable Two Factor Authentication for added security." : "Two Factor Authentication is currently enabled for your account.",
    image: userdata?.Google != "enable" ? tool : tick,
    btnname: userdata?.Google != "enable" ? "Enable 2FA" : "verified"
},
{
    title: "Anti-Phishing Code",
    minititle: userdata?.anticode ? "configured" :"Not yet configured",
    desc: userdata?.anticode  ? 'anti-phishing code,it will appear in all official emails from Arbitribe tio prevent phishing attempts.':'After you have successfully set up the anti-phishing code,it will appear in all official emails from Arbitribe tio prevent phishing attempts.',
    image:  userdata?.anticode ?  tick : tool ,
    btnname: userdata?.anticode ? "Edit Code" :  "Add Code"
},
]
    const [modalpopup, setModalpopup] = useState(false)
    const [buymodals, setBuymodals] = useState(false);
    const [apishow, setApishow] = useState(false)
    const [securityshow, setSecurityshow] = useState(false)
    const [volumess, setvolumess] = useState(0)
    const [accouninfo, setAccountinfo] = useState(init)
    const [advanced, setAdvanced] = useState(act)
    const [activities, setActivities] = useState([{
        title: "Activities",
        minititle: "Account Activities",
        desc: 'Account abnormal?',
        // image: tool,
        btnname: "View"
    },
    ]

    )

    useEffect(() => {
        const token = localStorage.getItem('usertoken') ?  localStorage.getItem('usertoken') : sessionStorage.getItem('usertoken')
  if(token){
    fetchdata()

  }
  fetchplans()
       
        setuserdata(reduxuserdata)
    }, [  modalpopup])


    const handleOnChange = (value) => {
        let phone = userdata.phonenumberstatus ? 25 : 0 
        let email = userdata.registered ? 25 : 0 
        let fa = userdata?.Google === "enable" ? 25  : 0  
        let an = userdata?.anticode ? 25 : 0 
        let ans = phone + email + fa + an 
        setvolumess(ans)
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        setAccountinfo(init)
        setAdvanced(act)
        handleOnChange()
    }, [userdata?.email]);

    

    return (
        <>
            {buymodals && <Buymodalpage plans={plandata} user={userdata} onDismiss={() => setBuymodals(false)} />}

         

            <div className={`${cascading.innerpages}`}>

                <Grid container >
                    <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                        <Sidebar />
                    </Grid>
                    <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
                        <div className={`${cascading.innercontent} ${cascading.settingpage} mb-4`}>
                            <div className={`container ${cascading.custom_container}`}>
                                <div className={`${cascading.titlerow}`}>
                                    <p className={`${cascading.title}`}>Account & Security</p>
                                </div>
                                <div className={`row`}>
                                    <div className={`col-12`}>

                                        <div className={`${cascading.cardstyle} ${cascading.accountcard}`}>
                                            <p className={`${cascading.title}`}>Account Info</p>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col sm={6} md={4} className='mt-3'>
                                                    <div className={`${cascading.name} d-flex align-items-center`}>
                                                        <span className='position-relative'>
                                                            <span className={`${cascading.timelinecircle}`}></span>

    {/* const [userdata , setuserdata] = useState(reduxuserdata)g */}

                                                            <img className={`${cascading.profileimg} rounded-circle`} src={ userdata?.profile ? `${config.IMG_URL}/profile/${userdata?.userid +"/"+userdata?.profile}`:profileicon} alt='images' />
                                                        </span>
                                                        <span className={`${cascading.title} ms-2`}>{userdata?.firstname + " " + userdata?.lastname}</span>
                                                        <span className={`${cascading.title} ms-2`}>
                                                            
                                                        <CopyToClipboard
                            text={`${config.Siteurl}/register/?ref=${Encryptdata(userdata?.refcode)}`}
                            onCopy={() =>
                                toast.success("Referral Link Copied", { autoClose: 1000 })
                            } >
                           <Button className={`${cascading.whtclr} ${cascading.themebtn}`} 
                                                         >{'Invite Link'}</Button>
                        </CopyToClipboard>

                                                            
                                                         
                                                         </span>
                                                    </div>
                                                </Col>
                                                <Col sm={6} md={4} className='mt-3'>
                                                    <div>
                                                        <p className={`${cascading.title} mb-0`}>Security Level:</p>
                                                        <span className='d-flex align-items-center'>
                                                            <Slider
                                                                min={0}
                                                                max={100}
                                                                value={volumess}
                                                                onChange={handleOnChange}
                                                                tooltip={false}
                                                            />
                                                            <span className={`${cascading.title} mb-0 ms-2`}>{volumess}%</span></span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className={`row`}>
                                                {accouninfo.map((e) => (
                                                    <div className={`col-lg-6 mt-3`}>
                                                        <Settingcard datas={e} popfunction={(e) => setModalpopup(e)} />
                                                    </div>
                                                ))}


                                            </div>
                                        </div>


                                        <div className={`${cascading.cardstyle} ${cascading.accountcard} mt-4`}>
                                            <p className={`${cascading.title}`}>Advanced Protection</p>
                                            <div className={`row`}>
                                                {advanced.map((e) => (
                                                    <div className={`col-lg-6 mt-3`}>
                                                        <Settingcard datas={e} popfunction={(e) => setModalpopup(e)} qr={(data)=>settwodata(data)}/>
                                                  
                                                 
                                                    </div>
                                                ))}


                                            </div>
                                        </div>

                                        {console.log("qrqrqrq",twodata)}
                                        <div className={`${cascading.cardstyle} ${cascading.accountcard} mt-4`}>
                                            <p className={`${cascading.title}`}>Account Activities</p>
                                            <div className={`row`}>
                                                {activities.map((e) => (
                                                    <div className={`col-lg-6 mt-3`}>
                                                        <Settingcard datas={e} popfunction={(e) => setModalpopup(e)}/>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className='mt-4 mb-4 text-center'>
                                            <Button onClick={() => setBuymodals(true)} className={`py-2 px-3 ${cascading.whtclr} ${cascading.themebtn}`}>Submit</Button>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </Grid>
                </Grid>
                   {modalpopup == "Add Profile" &&
                   <Settingmodal show={modalpopup} onHide={()=>setModalpopup(false)}/>                    
                   }
                   {modalpopup == "Change Email" &&
                   <Settingmodal show={modalpopup} onHide={()=>setModalpopup(false)}/>                    
                   }
                   {modalpopup == "verify phone number" &&
                   <Settingmodal show={modalpopup} onHide={()=>setModalpopup(false)}/>                    
                   }
                   {modalpopup == "Change Password" &&
                   <Settingmodal show={modalpopup} onHide={()=>setModalpopup(false)}/>                    
                   }
                   {modalpopup == "Enable 2FA" &&
                   <Settingmodal show={modalpopup} onHide={()=>setModalpopup(false)} qrdata={twodata}/>                    
                   }
                   {(modalpopup == "Add Code" || modalpopup == "Edit Code") &&
                   <Settingmodal show={modalpopup} onHide={()=>setModalpopup(false)}/>                    
                   }
                   {modalpopup == "View" &&
                   <Settingmodal show={modalpopup} onHide={()=>setModalpopup(false)}/>                    
                   }

                   {/* {modalpopup === "Verify Email" && 
                   
                   } */}
            </div>
        </>
    )
}

export default Settings