
import CryptoJS  from "crypto-js";



import config from '../config'


export const Encryptdata = (data) => {
  console.log("encodekeyysss" , config.SECRET_KEY)
    const encJson = CryptoJS.AES.encrypt(JSON.stringify(data), config.SECRET_KEY).toString();
    const encData = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encJson)
    );
    return encData;
  }
  
  export const Decryptdata = (data) => {
    try{
 
    const decData = CryptoJS.enc.Base64.parse(data)?.toString(CryptoJS.enc.Utf8);
    const bytes = CryptoJS.AES.decrypt(decData, config.SECRET_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(bytes)
    }
    catch(e){
      console.error(e)
      return ''
    }
  }
  