import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import Sidebar from '../common/Sidebar'
import { Link } from 'react-router-dom';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import {fetchbinding} from '../api/user.axios'
import Apicomponent from '../components/apibindingcontent'

function Apibinding() {

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);

    return (
        <div className={`${cascading.innerpages}`}>

            <Grid container >
                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                    <Sidebar />
                </Grid>
                <Grid item xl={10} lg={10} md={12} sm={12} xs={12} >
                    <div className={`${cascading.innercontent} ${cascading.apipage}`}>
                        <div className={`container ${cascading.custom_container}`}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <p className={`${cascading.maintitle} pt-5`}>API Binding</p>
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="pills" className="flex-row">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Binance</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Kucoin</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <Apicomponent eventKey="binance" />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Apicomponent eventKey="kucoin"/>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>

                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Apibinding