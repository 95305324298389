import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/landing.module.css'
import Grid from '@mui/material/Grid';
import { Col, Nav, Row, Tab, Form, Button, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../common/header';
import Footer from '../common/footer';
import lightingtop from "../assests/images/lightingtop.png";
import oneimg from "../assests/images/1.png";
import twoimg from "../assests/images/2.png";
import threeimg from "../assests/images/3.png";
import rosee from "../assests/images/rosee.png";
import roadmap from "../assests/images/roadmap.png";
import leftarrow from "../assests/images/leftarrow.svg";
import leftimg from "../assests/images/left.svg";
import rightimg from "../assests/images/right.svg"
import { fetchcmslist } from '../api/cms.axios';
import config from '../config'
function Landing() {
    const [cms, Setcms] = useState()
    const [faq, Setfaq] = useState()
    const [roadmap, Setroadmap] = useState()
    const [tab, Settab] = useState()
    const [box1, Setbox1] = useState()
    const [box2, Setbox2] = useState()
    const [box3, Setbox3] = useState()
    const [box4, Setbox4card] = useState()
    const [box5, Setbox5] = useState()
    const [box6, Setbox6] = useState()
    const [box2step1, Setbox2step1] = useState()
    const [box2step2, Setbox2step2] = useState()
    const [box2step3, Setbox2step3] = useState()
    const [bottomcard, Setbottomcard] = useState();
    const [accordionOpen, setAccordionOpen] = useState();
    const toggleAccordion = (i) => {
        setAccordionOpen(i === accordionOpen ? null : i);
    };
    useEffect(() => {
        fetchcms()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    const fetchcms = async () => {
        const data = await fetchcmslist()
        if (data?.status) {
            Setcms(data?.data?.cms)
            Setfaq(data?.data?.faq)
            Setroadmap(data?.data?.roadmap)
            Settab(data?.data?.tab)
            let cms = data?.data?.cms
            for (let i = 0; i < cms.length; i++) {
                let cms = data?.data?.cms[i]
                if (cms?.slug == "homepage_box1") {
                    Setbox1(cms)
                }
                if (cms?.slug == "homepage_box2") {
                    Setbox2(cms)
                }
                if (cms?.slug == "homepage_box3") {
                    Setbox3(cms)
                }
                if (cms?.slug == "homepage_box4_card") {
                    Setbox4card(cms)
                }
                if (cms?.slug == "homepage_box5") {
                    Setbox5(cms)
                }
                if (cms?.slug == "homepage_box6") {
                    Setbox6(cms)
                }
                if (cms?.slug == "homepage_box2_step1") {
                    Setbox2step1(cms)
                }
                if (cms?.slug == "homepage_box2_step2") {
                    Setbox2step2(cms)
                }
                if (cms?.slug == "homepage_box2_step3") {
                    Setbox2step3(cms)
                }
                if (cms?.slug == "homepage_bottomcard") {
                    Setbottomcard(cms)
                }

            }
        } else {
            console.error(data)
        }
        console.log("fetchcmsfetchcms", data)
    }
    return (

        <>
            <div className={`${cascading.landing}`}>

                <Header />
                <div className={`${cascading.landingpage}`}>
                    {/* <img src={lightingtop} className={`${cascading.lightimg}`}/> */}
                    <div className={`container`}>
                        <div className={`row pt-5 pb-5`}>
                            <div className={`col-sm-10 col-md-8 col-lg-7 col-xl-6`}>
                                <h1 className={`${cascading.heading}`}>Lorem ipsum
                                    dolor <span className={`${cascading.subtext}`}>sit amet</span> </h1>
                                <p className={`${cascading.liness}`}>
                                    <div dangerouslySetInnerHTML={{ __html: box1?.description }}></div>
                                </p>
                                <div className={`mt-4`}>
                                    <Button className={`btn ${cascading.landbtn}`}>Get Started</Button>
                                    <Button className={`btn ${cascading.landborderbtn} ms-3`}>Get Started</Button>
                                </div>
                            </div>
                        </div>

                        <div className={`row pt-5 pb-2`}>
                            <div className={`col-sm-9 col-md-8 col-xl-6 mx-auto`}>
                                {/* <h1 className={`${cascading.firsth1}`}>Fusce <span className={`${cascading.mainheading}`}>sollicitudin </span>
                                    lacinia ornare</h1> */}
                                <h1 className={`${cascading.firsth1}`}>{box2?.heading}</h1>

                            </div>
                        </div>

                        <div className={`row pt-4 pb-2 pb-md-5`}>
                            <div className={`col-md-12 col-lg-11 mx-auto`}>
                                <div className={`row justify-content-between`}>
                                    <div className={`col-md-4 col-lg-4 col-xl-3 ${cascading.position} mt-4`}>
                                        <img src={oneimg} className={`${cascading.oneimg}`} />
                                        <h5 className={`${cascading.step} ps-5 mt-4`}>Step</h5>
                                        <p className={`${cascading.liness} ps-5`}>
                                            <div dangerouslySetInnerHTML={{ __html: box2step1?.description }}></div>
                                        </p>
                                    </div>
                                    <div className={`col-md-4 col-lg-4 col-xl-3 ${cascading.position}  mt-4`}>
                                        <img src={twoimg} className={`${cascading.oneimg}`} />
                                        <h5 className={`${cascading.step} ps-5 mt-4`}>Step</h5>
                                        <p className={`${cascading.liness} ps-5`}>
                                            <div dangerouslySetInnerHTML={{ __html: box2step2?.description }}></div>  </p>
                                    </div>
                                    <div className={`col-md-4 col-lg-4 col-xl-3 ${cascading.position}  mt-4`}>
                                        <img src={threeimg} className={`${cascading.oneimg}`} />
                                        <h5 className={`${cascading.step} ps-5 mt-4`}>Step</h5>
                                        <p className={`${cascading.liness} ps-5`}>
                                            <div dangerouslySetInnerHTML={{ __html: box2step3?.description }}></div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`row pt-5 pt-sm-3 pb-5 align-items-center`}>
                            <div className={`col-md-6`}>
                                {/* <img src={rosee} className={`${cascading.rosee} img-fluid`} /> */}
                                <img src={box3?.image ? `${config?.IMG_URL}/cms/${box3?.image}` : rosee} className={`${cascading.rosee} img-fluid`} />
                            </div>
                            <div className={`col-md-6 `}>
                                {/* <h1 className={`${cascading.firsth1} text-start`}>rhoncus massa Fusce <span className={`${cascading.mainheading}`}>sollicitudin </span>
                                    lacinia ornare</h1> */}
                                <h1 className={`${cascading.firsth1} text-start`}>{box3?.heading}</h1>
                                <p className={`${cascading.liness} mt-4`}>
                                    <div dangerouslySetInnerHTML={{ __html: box3?.description }}></div> </p>
                                <Button className={`btn ${cascading.landborderbtn} mt-2 ms-0`}>Get Started</Button>
                            </div>
                        </div>

                        <div className={`row pt-4 pb-5`}>
                            <div className={`col-md-12 mx-auto`}>
                                <div className={`${cascading.backimg} py-3 py-sm-5 px-3 px-sm-5`}>
                                    <div className={`row py-3`}>
                                        <div className={`col-sm-12 col-md-8 col-lg-7`}>
                                            <h4 className={`${cascading.firsth1} text-start`}>{box4?.heading}</h4>
                                            <p className={`${cascading.liness}`}>  <div dangerouslySetInnerHTML={{ __html: box4?.description }}></div>
                                            </p>
                                            <Button className={`btn ${cascading.landbtn} mt-2`}>Get Started</Button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`row pt-4 pt-xl-5 pb-4 pb-sm-5`}>
                            <div className={`col-md-8 col-lg-6`}>
                                {/* <h1 className={`${cascading.firsth1} text-start`}>Fusce <span className={`${cascading.mainheading}`}>sollicitudin </span>
                                    lacinia ornare</h1> */}
                                <h1 className={`${cascading.firsth1} text-start`}>{box5?.heading}</h1>
                            </div>
                            <div className={`${cascading.landtabs} mt-4`}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                                    <Row>
                                        <Col sm={6} md={5} xl={4}>
                                            <Nav variant="pills" className={`${cascading.navall} flex-column`}>
                                                {tab?.map((data, index) => (
                                                    <Nav.Item>
                                                        <Nav.Link eventKey={index} >
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                            <div dangerouslySetInnerHTML={{ __html: data?.question }}></div>
                                                            <img src={leftarrow} className={`${cascading.leftarrowss} img-fluid ms-2`} />
                                                            </div>
                                                          
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                        </Col>
                                        <Col sm={6} md={7} xl={8}>
                                            <Tab.Content>
                                                {tab?.map((data , index) => (
                                                    <Tab.Pane eventKey={index}><p className={`${cascading.liness}`}>
                                                        {/* {data?.answer} */}
                                                        <div dangerouslySetInnerHTML={{ __html: data?.answer }}></div>
                                                    </p>                                             </Tab.Pane>))}

                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>

                        <div className={`row pt-4 pt-xl-5 pb-4 pb-sm-5`}>
                            <div className={`col-sm-10 col-lg-6 col-md-7 mx-auto`}>
                                <h1 className={`${cascading.firsth1}`}>ROADMAP</h1>
                                <p className={`${cascading.liness} text-center pb-3 pb-sm-5 pb-md-0 mb-3 mb-sm-5`}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus blandit dui sit amet biben maximus onsectetur dignissim.
                                </p>
                            </div>
                            <div className={`col-sm-9 col-md-7 col-lg-11 col-xl-10 col-xxl-9 mx-auto ${cascading.mainroad}`}>
                                {/* <img src={roadmap} className={`${cascading.roadmap} img-fluid mt-4`} /> */}

                                <div className={`${cascading.roadwrapper}`}>
                                    <Accordion defaultActiveKey="first">
                                        {roadmap?.map((data, index) =>
                                            <Accordion.Item eventKey={index == 0 ? "first" : index}>
                                                <Accordion.Header><span className={`${cascading.fw600} me-2`}>{data?.step}</span> {data?.question}</Accordion.Header>
                                                <Accordion.Body>

                                                    <div dangerouslySetInnerHTML={{ __html: data?.answer }}></div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        )
                                        } </Accordion>

                                </div>




                            </div>
                        </div>



                        <div className={`row pt-5 pb-4 pb-sm-5`}>
                            <div className={`col-md-6 mx-auto`}>
                                <h1 className={`${cascading.firsth1} mb-4 mb-ms-5`}>FAQ</h1>
                            </div>
                            <div className={`col-md-12 mx-auto ${cascading.accordionland}`}>
                                {/* {console.log("dsagdasg" , faq[0])} */}
                                <Accordion defaultActiveKey="first">
                                    {faq?.map((data, index) => <Accordion.Item eventKey={index == 0 ? "first" : index}>
                                        {console.log("indexxxxx" ,index )}
                                        <Accordion.Header onClick={() => toggleAccordion(index + 1)}>{data?.question}</Accordion.Header>
                                        <Accordion.Body>
                                            <div dangerouslySetInnerHTML={{ __html: data?.answer }}></div>

                                        </Accordion.Body>
                                    </Accordion.Item>)}





                                </Accordion>
                            </div>
                        </div>


                        <div className={`row pt-4 pb-5`}>
                            <div className={`col-md-12 mx-auto`}>
                                <div className={`${cascading.backimg} ${cascading.backlastimg} py-2 py-sm-5 px-3 px-sm-5`}>
                                    <div className={`row py-3 py-sm-3 py-md-5`}>
                                        <div className={`col-md-8 col-lg-7 py-4 mx-auto text-center`}>
                                            <h4 className={`${cascading.firsth1} text-capitalize`}>{bottomcard?.heading}</h4>
                                            <p className={`${cascading.liness}`}>
                                                <div dangerouslySetInnerHTML={{ __html: bottomcard?.description }}></div>
                                            </p>
                                            <Button className={`btn ${cascading.landbtn} mt-2`}>Get Started</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                    {/* <main>
                                    <p>Doggo ipsum long bois lotsa pats blep. What a nice floof ruff super chub very good spot, the neighborhood pupper lotsa pats. Borkdrive shibe shoober what a nice floof, borking doggo.</p>
                                    <p>Shoober shooberino adorable doggo many pats, heckin good boys many pats pupper wrinkler, corgo maximum borkdrive. A frighten puggo wow very biscit.</p>
                                    <p>Big ol h*ck adorable doggo vvv smol borking doggo with a long snoot for pats big ol, he made many woofs doing me a frighten puggo wow very biscit, ruff fat boi ruff long doggo. </p>
                                    <p>Long bois mlem I am bekom fat wrinkler puggo maximum borkdrive big ol pupper I am bekom fat, fluffer vvv adorable doggo lotsa pats snoot. I am bekom fat ur givin me a spook length boy wow very biscit very good spot.</p>
                                    <p>Doggo ipsum long bois lotsa pats blep. What a nice floof ruff super chub very good spot, the neighborhood pupper lotsa pats. Borkdrive shibe shoober what a nice floof, borking doggo.</p>
                                </main> */}

                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Landing