import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import Sidebar from '../common/Sidebar'
import { Link, useNavigate } from 'react-router-dom';
import { Col, Nav, Row, Tab ,Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { fetchbinding } from '../api/user.axios';
import { resendotp } from '../api/user.axios';
import CryptoJS from 'crypto-js';
import Config from '../config'
import CopyToClipboard from 'react-copy-to-clipboard';
function Apibindingcontent(props) {
const dispatch = useDispatch()
    const reduxdata = useSelector(state => state?.redux.userdata)
    const {eventKey} = props

const navigate = useNavigate()
let originalapikey ,passphrase = "" ,originalsecuritykey;
    useEffect(()=>{
        setuserdata(reduxdata)
        setformdata(initdata)
        try{

            let apikey = CryptoJS.AES.decrypt(reduxdata?.[eventKey].apikey, Config.SECRET_KEY);
            originalapikey = apikey?.toString(CryptoJS.enc.Utf8);
            let securitykey = CryptoJS.AES.decrypt(reduxdata?.[eventKey].securitykey, Config.SECRET_KEY);
             originalsecuritykey = securitykey?.toString(CryptoJS.enc.Utf8);
             let passphraseKEY
             let origpassphrase;
             if(eventKey == "kucoin"){
                let passphraseKEY = CryptoJS.AES.decrypt(reduxdata?.[eventKey].passphrase, Config.SECRET_KEY);
                origpassphrase = passphraseKEY?.toString(CryptoJS.enc.Utf8);
             }
             

            //  let apikey = CryptoJS.AES.decrypt(reduxdata?.apikey, Config.SECRET_KEY);
            //  originalapikey = apikey?.toString(CryptoJS.enc.Utf8);
            //  let securitykey = CryptoJS.AES.decrypt(reduxdata?.securitykey, Config.SECRET_KEY);
            //   originalsecuritykey = securitykey?.toString(CryptoJS.enc.Utf8);
            //   let passphraseKEY
            //   let origpassphrase;
            // //   if(eventKey == "kucoin"){
            // //      let passphraseKEY = CryptoJS.AES.decrypt(reduxdata?.[eventKey].passphrase, Config.SECRET_KEY);
            // //      origpassphrase = passphraseKEY?.toString(CryptoJS.enc.Utf8);
            // //   }
             setformdata({...formdata,...{'apikey' :originalapikey , 'securitykey' : originalsecuritykey , 'passphrase' : origpassphrase}})
             
        }catch(error){
            console.error(error)
        }
    },[reduxdata , eventKey ])

  

    const[otpbox , setotpbox]=useState(false)
    const [userData , setuserdata ] = useState()
    const [apishow, setApishow] = useState(false)
    const [securityshow, setSecurityshow] = useState(false)
    const [phraseshow, setPhraseyshow] = useState(false)
console.log('userDatauserDatauserData-->',userData)
    const initdata = {
        "eventkey" : eventKey,
        "apikey":originalapikey ? originalapikey : "",
        "securitykey": originalsecuritykey ? originalsecuritykey :"",
        'passphrase' : passphrase ?? "",
        "email" : "",
        "otp" : "",
        "check": false 
    }
    // const initdata = {
    //     "eventkey" : eventKey,
    //     "apikey": "",
    //     "securitykey": "",
    //     "email" : "",
    //     "otp" : "",
    //     "check": false 
    // }


    const [Errors,setErrors]=useState({})
    console.log("Errors" , Errors)
    const [process,Setprocess]=useState(false)
    const [formdata , setformdata]=useState(initdata)

    useEffect(()=>{
        setformdata({...formdata,...{'email' : userData?.email}})
    },[userData])
    console.log(formdata)
    const onupdate = (e)=>{
        setErrors({})
        const {id , value } = e.target
        if(id === 'check'){
            setformdata({...formdata , [id] : e.target.checked})
        }else{
            setformdata({...formdata , [id] : value})
        }
    }

const onsubmit = async()=>{
    const id = toast.loading('uploading')
try{
    Setprocess(true);
    // const initdataSchema = yup.object({
    //     eventkey: yup.string().required(),
    //     apikey: yup.string().required('api key required'),
    //     securitykey: yup.string().required('security key required'),
    //     email: yup.string().email().required('email required'),
    //     otp: yup.string().required('otp required'),
    //     check: yup.boolean().required().oneOf([true], 'read the risk carefully'),
    //     passphrase: yup.string().when('eventkey', {
    //         is: 'kucoin',
    //         then: yup.string().required('Passphrase required for Kucoin'),
    //         otherwise: yup.string().notRequired() // Optionally define schema when condition is not met
    //     })
    //   });
    const initdataSchema = yup.object({
        eventkey: yup.string().required(),
        apikey: yup.string().required('API key required'),
        securitykey: yup.string().required('Security key required'),
        email: yup.string().email().required('Email required'),
        otp: yup.string().required('OTP required'),
        check: yup.boolean().required().oneOf([true], 'Read the risk carefully'),
        passphrase: yup.string().when('eventkey', {
            is: (value) => value === 'kucoin',
            then: initdataSchema => initdataSchema.required(),
            otherwise: initdataSchema => initdataSchema.optional(),
            // then: yup.string().required('Passphrase required for Kucoin'),
            // otherwise: yup.string().nullable() // Optionally define schema when condition is not met
        })
    });
    
     await initdataSchema.validate(formdata , {abortEarly : false})


      if(formdata?.email != userData?.email){
        toast.dismiss(id)
        toast.error("Email does not match");
        const newErrors = {};
        newErrors["email"] = "Email does not match"
        setErrors(newErrors);
        return 
      }

      const response = await fetchbinding(formdata)
console.log('responseresponseresponseresponse-->',response)
      toast.dismiss(id);
      if(response.status){
        toast.success(response.message)

        dispatch({
            type : "userdata" , 
            data : { userdata: response?.data}
        })
        // navigate('/multiarbi')
      }else{
        toast.error(response.message)
      }
      setotpbox(false)

}catch(error){
      toast.dismiss(id);
            toast.error("validation error");
            const newErrors = {};
            console.log('error.inner-->',error)
            error?.inner?.forEach((err) => {
              newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
}finally{
    Setprocess(false);
}

}

const  resendotpfunction = async()=>{
    const id = toast.loading("Sending OTP")
      let senddata ={
          email : formdata?.email
      }
      if(!formdata?.email){
        toast.dismiss(id)
        toast.error("email required");
        const newErrors = {};
        newErrors["email"] = "email required"
        setErrors(newErrors);
        return 
      }
      if(formdata?.email != userData?.email){
        toast.dismiss(id)
        toast.error("Email does not match");
        const newErrors = {};
        newErrors["email"] = "Email does not match"
        setErrors(newErrors);
        return 
      }

      const resendotpresponse = await resendotp(senddata)
      console.log("resendotpresponseresendotpresponse" , resendotpresponse)
      toast.dismiss(id)
      if(resendotpresponse?.message.includes('succesfully')){
           toast.success(resendotpresponse?.message);
           setotpbox(true)
          //  navigate('/login')
      }else{
          toast.error(resendotpresponse?.message);
      }
  }

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);
    return (

        <div>
            <div className={`${cascading.titlerow}`}>
                <div className={`row ${cascading.notfysec} mb-4`}>
                    <div className={`${cascading.cardstyle}`}>
                        <p className={`${cascading.head}`}>Notification</p>
                        <hr className={`${cascading.hrline}`} />
                        <p className={`${cascading.desc}`}>1. Please confirm that the API permissions has checked “Enable Spot & Margin Trading” Do not check “Withdraw” option
                        </p>
                        <p className={`${cascading.desc}`}>2. Please enter the correct API please do not enter special character.</p>
                            <p className={`${cascading.desc}`}>3. Please Ensure For IP Restriction and Allow This IP. <CopyToClipboard
                            text={`3.108.129.115`}
                            onCopy={() =>
                                toast.success("IP Address Copied", { autoClose: 1000 })
                            } >
                           <Button className={`${cascading.whtclr} ${cascading.themebtn}`} 
                                                         >{'Copy Address'}</Button>
                        </CopyToClipboard></p>
                        <p className={`${cascading.desc}`}>4. Please make sure should Must Have Balance Greater Than 0 {eventKey == 'binance' ? 'BNB' : 'Kucoin'}.</p>

                    </div>
                </div>
                <div className={`row ${cascading.formsec} mb-4`}>
                    <div className={`${cascading.cardstyle}`}>
                        <div className={`col-12 mb-4`}>
                            <p className={`${cascading.labelname}`}>API key</p>
                            <div className={`input-group ${cascading.inputstyle}`}>

                                <input type={apishow ? "text" : "password"} onChange={(e)=>onupdate(e)} id="apikey" value={formdata?.apikey}  className={`form-control`} aria-label="" placeholder='Enter API Key' />
                                <div className={`input-group-append`} onClick={() => setApishow(!apishow)}>
                                    <span className={`input-group-text`} >{apishow ? <FaRegEye fill='#fff' /> : <FaRegEyeSlash fill='#fff' />}</span>
                                </div>
                            </div>
                            {Errors?.apikey && (
                        <span class="text-danger">{Errors?.apikey}</span>
                      )}
                        </div>
                        <div className={`col-12 mb-4`}>
                            <p className={`${cascading.labelname}`}>Security Key</p>
                            <div className={`input-group ${cascading.inputstyle}`}>

                                <input type={securityshow ? "text" : "password"}  id="securitykey" value={formdata?.securitykey} onChange={(e)=>onupdate(e)} className={`form-control`} aria-label="" placeholder='Enter Security Key' />
                                <div className={`input-group-append`} onClick={() => setSecurityshow(!securityshow)}>
                                    <span className={`input-group-text`} >{securityshow ? <FaRegEye fill='#fff' /> : <FaRegEyeSlash fill='#fff' />}</span>
                                </div>
                     
                            </div>
                            {Errors?.securitykey && (
                        <span class="text-danger text-start">{Errors?.securitykey}</span>
                      )}
                        </div>
                        { eventKey == 'kucoin' && <div className={`col-12 mb-4`}>
                            <p className={`${cascading.labelname}`}>Passphrase</p>
                            <div className={`input-group ${cascading.inputstyle}`}>

                                <input type={phraseshow ? "text" : "password"}  id="passphrase" value={formdata?.passphrase} onChange={(e)=>onupdate(e)} className={`form-control`} aria-label="" placeholder='Enter Passphrase' />
                                <div className={`input-group-append`} onClick={() => setPhraseyshow(!phraseshow)}>
                                    <span className={`input-group-text`} >{phraseshow ? <FaRegEye fill='#fff' /> : <FaRegEyeSlash fill='#fff' />}</span>
                                </div>
                     
                            </div>
                            {Errors?.passphrase && (
                        <span class="text-danger text-start">{Errors?.passphrase}</span>
                      )}
                        </div>}
                      
                        <div className={`col-12 mb-4`}>
                            <p className={`${cascading.labelname}`}>A verification code will be sent to {userData?.email?.slice(0,4)+"***"+userData?.email?.slice(-10) }</p>
                            <div className={`row`}>
                                <div className={`col-8 col-sm-9 col-lg-11`}>
                                    <input type="email" class="form-control" onChange={(e)=>onupdate(e)} id="email" value={formdata?.email} disabled={true} placeholder="Enter email for verification code" aria-label="" aria-describedby="basic-addon1" />
                                
                                </div>
                                <div className={`col-4  col-sm-3 col-lg-1`}>
                                    <button className={`btn ${cascading.greenbtn}`} disabled={process} onClick={()=>resendotpfunction()}>{otpbox ? "ReSend" :"Send"}</button>
                                </div>
                                {Errors?.email && (
                               <span class="text-danger text-start">{Errors?.email}</span>
                                 )}
                            </div>
                           
                        </div>

                        { otpbox && <div className={`col-12 mb-4`}>
                            <p className={`${cascading.labelname}`}>OTP send to your email kindly check  inbox and spam </p>
                            <div className={`row`}>
                                <div className={`col-8 col-sm-9 col-lg-11`}>
                                    <input type="email" class="form-control" onChange={(e)=>onupdate(e)} id="otp" value={formdata?.otp}  placeholder="Enter One-time password" aria-label="" aria-describedby="basic-addon1" />
                                </div>
                                
                                {Errors?.email && (
                               <span class="text-danger">{Errors?.email}</span>
                                 )}
                            </div>
                        </div>}

                        <div className={`col-12 mb-4`}>

                            <div className={`form-check ${cascading.formcheck}`}>
                                <input type="checkbox" onChange={(e)=>onupdate(e)} className={`form-check-input`} id="check" />
                                <label className={`form-check-label`} for="exampleCheck1">I have <Link to="#">read the risk notice carefully</Link></label>
                    
                            </div>
                            {Errors?.check && (
                        <span class="text-danger">{Errors?.check}</span>
                      )}
                        </div>
                        <div className={`col-12 mb-4`}>
                            <div className={`${cascading.btnsec}`}>
                                <button className={`btn ${cascading.greenbtn}`} onClick={onsubmit} disabled={process}>Bind</button>
                                <button className={`btn ${cascading.redbtn}`} onclick={()=>{ setformdata(initdata)}}>Delete</button>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

        </div>
    )
}

export default Apibindingcontent