import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import cascading from "../assests/css/all.module.css";
import cascading1 from "../assests/css/settingcard.module.css";
import { addsubcription, fetchplanLists } from "../api/user.axios";
import {useSelector} from 'react-redux'
import toast from "react-hot-toast";
import Header from '../common/header';
import Footer from '../common/footer';
import logo from '../assests/images/logo.png';
import { isEmpty } from "../actions/common";
import { TransferToken, getBalance } from "../hook/contract";

function Pricing() {
    
    const user = useSelector(state => state?.redux?.userdata)
    const account = useSelector(state => state?.redux?.AccountDetails)

  const [plandata, setplandata] = useState({});

  useEffect(() => {
    fetchplans();
  }, []);
  const fetchplans = async () => {
    const resp = await fetchplanLists({from : 'user'});
    console.log('fetchplanLists-->',resp)
    if (resp?.status) {
      setplandata(resp?.data);
    }
  };

  const chooseplan = async (data) => {
    if(isEmpty(account)){
        toast.error("Please Connect Your Wallet")
    }else{
        console.log('account?.accountAddress-->',account?.accountAddress)
        // balance check
        let balid = toast.loading("Balance Checking...")
        let balance = await getBalance(account?.accountAddress,account.web3)
        console.log('balancebalance-->',balance)

        if(!(balance >= data.planprice)){
            return toast.error("You Have Not Enough Balance",{id:balid})
        }
        else{
          toast.success("Balance Exist",{id : balid})
        }
        let id = toast.loading("purchasing...")
        let transfer = await TransferToken(account?.accountAddress,Number(data.planprice*(10 ** 18)),account.web3)
         if(transfer?.status){
          let senddata = {
            userid: user?.userid,
            transactionid: "demotransactionid",
            planid: data?._id,
          };
          const response = await addsubcription(senddata);
          if (response?.status) {
            toast.success(response?.message,{
              id: id,
            });
           
          } else {
            toast.error(response?.message,{
              id: id,
            });
          }
         }  
         else{
          toast.error("Something went Wrong",{
            id: id,
          })
         }
        
        
    }
    
  };

  return (
 <>
   <Header />
   <div className='pt-5 pb-5'>
        <div className={`${cascading1.pricing} container`}>
      {/* <h4 className={`${cascading.titlesss} text-center`}>Choose your plan</h4> */}
      <h1 className={`${cascading.firsth1} mb-5 text-center`} style={{ color: "#C9FF69" }}>Choose your plan</h1>
      <div className="row justify-content-center">
        
        {plandata.length > 0 ? plandata?.map((data) => (
          <div
            className={`${cascading1.cardfull} col-11 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-4`}>
            <div className={`${cascading1.settingscard}`}>
              <h5 className={`${cascading.whtclr}`}>{data?.planname}</h5>
              <p className={`${cascading.porttext}`}>Reward ${data?.reward}</p>
              <p className={`${cascading.porttext} mt-2`}>
                For individuals getting started with portfolio management
              </p>
              <h3 className={`${cascading.permonth} mt-4`}>
                ${data?.planprice} for {data?.planperiod} Days{" "}
              </h3>
              <Button
                className={`${cascading.whtclr} ${cascading.themebtn} mt-3`}
                onClick={() => chooseplan(data)}
              >
                Choose Plan
              </Button> 
              
            </div>
          </div>
        ))
    :
    <h3 className={`${cascading.noplan} mt-4`}>
               No Plans Available
              </h3>}

        {/* <div className={`${cascading1.cardfull} col-sm-10 col-md-9 col-lg-6 col-xl-4 mt-4`}>
    <div className={`${cascading1.settingscard}`}>
        <h5 className={`${cascading.whtclr}`}>Plus</h5>
        <p className={`${cascading.porttext}`}>Portfolio: up to $10K</p>
        <p className={`${cascading.porttext} mt-2`}>For advanced individuals and
            professionals</p>
        <h3 className={`${cascading.permonth} mt-4`}>$25 per month</h3>
        <p className={`${cascading.porttext}`}>with annual payment</p>
        <Button className={`${cascading.whtclr} ${cascading.themebtn} mt-3`}>Choose Plan</Button>
    </div>
</div>
<div className={`${cascading1.cardfull} col-sm-10 col-md-9 col-lg-6 col-xl-4 mt-4`}>
    <div className={`${cascading1.settingscard}`}>
        <h5 className={`${cascading.whtclr}`}>Pro</h5>
        <p className={`${cascading.porttext}`}>Portfolio: $10K+</p>
        <p className={`${cascading.porttext} mt-2`}>For seasoned players and
            corporations</p>
        <h3 className={`${cascading.permonth} mt-4`}>$50</h3>
        <p className={`${cascading.porttext}`}>annual deposit</p>
        <Button className={`${cascading.whtclr} ${cascading.themebtn} mt-3`}>Choose Plan</Button>
    </div>
</div> */}
      </div>
    </div>
    </div>

            <Footer />
 </>
  
  );
}
export default Pricing;
