import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Col, Nav, Row, Tab, ButtonGroup, Button, InputGroup, Form, Table } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import cascading from '../assests/css/all.module.css';



export default function DataTables(props) {
    const { rowdata } = props
   
console.log('rows222-->',rowdata)
    const rows = [];

    for (let i = 0; i < rowdata?.length; i++) {

        let data = {
          id: i + 1,
          userid: rowdata?.[i]?.from,
          Reward: rowdata?.[i]?.Reward + " USDT",
          email : rowdata?.[i]?.email
        };
        rows.push(data)
    }
    console.log('rows111-->',rows)

    return (
        <>
        <div>
            <div className='alltables'>
                <Table responsive>
                    <thead>
                        <th>From</th>
                        <th>Email</th>
                        <th>Reward</th>
                    </thead>
                    <tbody>
                    {rows.length > 0 ? <> {rows.map((data)=> <tr>
                      <td>{data.userid}</td>
                      <td>{data?.email}</td>
                      <td>{data?.Reward}</td>
                  </tr>
                    )}
              </>
                    : <h2 class='text-center mt-auto no_data_center'> no data found</h2>}
                      



                    </tbody>

                </Table>
           

            </div>


        </div>
    </>
    );
}