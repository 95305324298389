import config from "../config";
import { AppenData, axiosFunc } from "../actions/common";
import axios from 'axios'
import { Decryptdata, Encryptdata } from "../actions/encode_decode";
let token = sessionStorage.getItem("usertoken")
    ? sessionStorage.getItem("usertoken")
    : localStorage.getItem("usertoken");


export const getArbitrages = async (data) => {
    let senddata = {
        method: "GET",
        url: `${config.TRADE_URL}/getarbitrages`,
        params: { data: Encryptdata(data) },
        headers: {
            contentype: "application/json",
        },
    };
    console.log(senddata);
    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);
    return response.data;
};

export const fetchtickers = async (data) => {
    let token = sessionStorage.getItem("usertoken")
        ? sessionStorage.getItem("usertoken")
        : localStorage.getItem("usertoken");

    let senddata = {
        method: "GET",
        url: `${config.TRADE_URL}/ticker`,
        params: { data: Encryptdata(data) },
        headers: {
            contentype: "application/json",
            // authorization: token,
        },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);
    console.log("klkmm", response.data)
    return response.data;
}

export const Getmarketdepth =
    async (symbol, chain) => {
        try {
            console.log('Getmarketdepth-->', symbol, chain)
            if (chain == 'bnb') {
                let sendData = {
                    method: "GET",
                    url: `${config.BNBBaseUrl}${'/api/v3/depth'}?symbol=${symbol}&limit=1000`
                }
                let data = await axios(sendData)

                return data;
            }
            else {

                let sendData = {
                    method: 'post',
                    url: `${config.BACK_URL}/user/getorderbook`,
                    data: { data: Encryptdata({ symbol, chain }) }
                }
                let response = await axiosFunc(sendData);
                response.data = Decryptdata(response?.data);    
                console.log("enndatavara", response.data)
                return response.data;
                // let sendData = {
                //     method: "post",
                //     url: `${config.KCCBaseUrl}${'/api/v1/market/orderbook/level2_20'}?symbol=${symbol}`
                // }
                // let data = await axios(sendData)

                // return data;
            }
        } catch (err) {
            console.log('Getmarketdepth_err-->', err)
            return { asks: [], bids: [] }
        }



    }

export const Getorderbook = async (data) => {
    let token = sessionStorage.getItem("usertoken")
        ? sessionStorage.getItem("usertoken")
        : localStorage.getItem("usertoken");

    let senddata = {
        method: "POST",
        url: `${config.TRADE_URL}/orderbook`,
        data: { data: Encryptdata(data) },
        headers: {
            contentype: "application/json",
            // authorization: token,
        },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);
    console.log("klkmm", response.data)
    return response.data;
}

export const Canceltrade = async (data) => {
    let token = sessionStorage.getItem("usertoken")
        ? sessionStorage.getItem("usertoken")
        : localStorage.getItem("usertoken");

    let senddata = {
        method: "POST",
        url: `${config.TRADE_URL}/cancelorder`,
        data: { data: Encryptdata(data) },
        headers: {
            contentype: "application/json"
        },
    };

    let response = await axiosFunc(senddata);
    response.data = Decryptdata(response?.data);
    return response.data;
}