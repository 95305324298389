import React, {useState, useEffect} from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import Sidebar from '../common/Sidebar';
import { Col, Nav, Row, Tab, ButtonGroup, Button } from 'react-bootstrap';
import Tickertable from '../components/tickertable'
import Datatable from '../components/rewardlisttable'
import {fetchtickers} from '../api/trade.axios'
import {useSelector} from 'react-redux';
import { Claimreward, GetRewardlist, fetchreconnect } from '../api/user.axios';
import { TransferToken } from '../hook/contract';
import toast from 'react-hot-toast'
import { isEmpty } from '../actions/common';
import {useDispatch} from 'react-redux'
import Walletmodal from '../components/walletmodel';
import Claim from '../components/claim';

function Refferlist() {
    const dispatch = useDispatch()
    const user = useSelector(state => state?.redux?.userdata)
    const account = useSelector(state => state?.redux?.AccountDetails)
    const [sendAddress,setSendAddress] = useState('')
    const [openmodel,setOpenmodel]=useState(false)
    const [wallet,setWallet]=useState(false)
    const [rowdata, setRowdata]=useState([])
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);

      useEffect(()=>{
          fetchrewardlist()
      },[user])

      async function fetchdata(){
        const res = await fetchreconnect()
        console.log("fetchdatafetchdatafetchdata",res?.status)  
    if(res?.status){
        dispatch({
            type : "userdata" , 
            data : { userdata: res?.data}
        })
    }else{
        if(res?.message){
                dispatch({
                  type : "userdata" , 
                  data : { userdata: ""}
                })
    }
    }
    }

    const fetchrewardlist = async()=>{
        let res = await GetRewardlist({userid : user?.userid})
        console.log('fetchrewardlist-->',res)
            setRowdata(res?.data)
    }

    const claimreward = async()=>{
        // if(isEmpty(account)){
        //     toast.error("Please Connect Wallet")
        //     setWallet(true)
        // }else{
            let amount = Number(user?.remainreward) // 0.00001 // Number(user?.remainreward) 
            // rowdata?.length > 0 ? rowdata?.reduce((acc,cv)=>acc+Number(cv.Reward),0) : 0
            if(Number(amount) > 0){
                let id = toast.loading("Token Transfering ...")
                // let resp = await TransferToken(sendAddress,Number(amount*(10 ** 18)),account.web3,true)
                // if(resp?.status){
                 let res = await Claimreward({userid : user?.userid , amount : amount , toaddress : sendAddress})
                 if(res){
                    toast.success(res?.message,{id : id})
                 }
                // }
            // }
            // else{
            //     toast.error("Amount is Too Small")
            // }
            fetchdata()
        } 
    }
    return (
        <>
        {wallet && <Walletmodal onDismiss={() => setWallet(false)} isOpen={wallet}/>}
         {openmodel && <Claim isOpen={openmodel} onDismiss={() => setOpenmodel(false)}  setadd={(e)=>setSendAddress(e)} claim={claimreward}/>}
        <div className={`${cascading.innerpages}`}>

            <Grid container >
                <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                    <Sidebar />
                </Grid>
                <Grid item xl={10} lg={10} md={12} sm={12} xs={12} >
                    <div className={`${cascading.innercontent} ${cascading.apipage}`}>
                        <div className={`container ${cascading.custom_container}`}>

                            <p className={`${cascading.maintitle} text-start pt-2`}>Refferal List</p>
                            <div className='text-end'>
                                {/* <ButtonGroup aria-label="Basic example" className='mb-3'>
                                    <Button variant="secondary">1 day</Button>
                                    <Button variant="secondary">7 days</Button>
                                    <Button variant="secondary">30 days</Button>
                                    <Button variant="secondary">All</Button>
                                </ButtonGroup> */}
                            </div>
                            <Row className='mb-4'>
                                <Col xl={3} lg={4} md={4} sm={6} className='mt-3'>
                                    <div className={`${cascading.dashborder} py-3 px-3`}>
                                        <h5 className={`${cascading.whtclr}`}>Total Reward</h5>
                                        <p className={`${cascading.whtclr} ${cascading.f14} mb-0`}>{rowdata?.length > 0 ? rowdata?.reduce((acc,cv)=>acc+Number(cv.Reward),0) : '0'} USDT</p>
                                    </div>
                                </Col>
                                <Col xl={3} lg={4} md={4} sm={6} className='mt-3'>
                                    <div className={`${cascading.dashborder} py-3 px-3`} 
                                    onClick={()=>setOpenmodel(true)}
                                    // onClick={()=>claimreward(rowdata?.length > 0 ? rowdata?.reduce((acc,cv)=>acc+Number(cv.Reward),0) : 0)}
                                    >
                                        <h5 className={`${cascading.whtclr}`}>Claim Reward</h5>
                                        <p className={`${cascading.whtclr} ${cascading.f14} mb-0`}>{user?.remainreward } USDT</p>
                                    </div>
                                </Col>
                                <Col xl={3} lg={4} md={4} sm={6} className='mt-3'>
                                    <div className={`${cascading.dashborder} py-3 px-3`} 
                                    // onClick={()=>setOpenmodel(true)}
                                    // onClick={()=>claimreward(rowdata?.length > 0 ? rowdata?.reduce((acc,cv)=>acc+Number(cv.Reward),0) : 0)}
                                    >
                                        <h5 className={`${cascading.whtclr}`}>Total Cliamed Amount</h5>
                                        <p className={`${cascading.whtclr} ${cascading.f14} mb-0`}>{user?.claimedreward} USDT</p>
                                    </div>
                                </Col>
                            </Row>

                            <div className={`${cascading.tablelist} pt-0 pb-2 mb-5`}>
                                <Datatable rowdata={rowdata}/>
                            </div>

                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
        </>
        
    )
}

export default Refferlist