import React, { useEffect, useState } from 'react'
import cascading from '../assests/css/landing.module.css'
import Grid from '@mui/material/Grid';
import { Col, Nav, Row, Tab, Form, Button, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../common/header';
import Footer from '../common/footer';
import { fetchcmslist } from '../api/cms.axios';

function Terms() {
    
const [conditions,setconditions]=useState('')
    useEffect(() => {
        contentfetch()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);


const contentfetch= async()=>{

    const res  =    await fetchcmslist()

    console.log("kaldkasldkal",res)
    if(res?.status){
let data = res?.data.cms.find((data)=> data.slug ==='teamsandconditions')
        setconditions(data)
    }
}

    return (

        <>
            <Header />
            <div className='pt-5 pb-5'>
                <div className={`container`}>
                    {/* <div className={`d-flex ${cascading.h100}`}>
                    <h2 className={`${cascading.liness}`}>Coming Soon..</h2>
                </div> */}
                  <div className={`${cascading.cardstyle} ${cascading.accountcard} py-5 px-3 px-sm-5`}>
                   <h1 className={`${cascading.firsth1} mb-5 text-center`} style={{ color: "#C9FF69" }}>Terms & Conditions</h1>
                    <p className={`${cascading.liness}`}>
                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
                    <br/><br/>
                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                    <br/><br/>
                    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
                    <br/><br/>
                    "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
            */}
{conditions?.description && 
<div dangerouslySetInnerHTML={{__html: conditions?.description}}></div> 
}
</p> 
                </div>
                </div>
            </div>

            <Footer />


        </>

    )
}

export default Terms  