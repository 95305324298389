import React, { useState, useEffect } from "react";
import cascading from "../assests/css/all.module.css";
import cascading1 from "../assests/css/settingcard.module.css";
import "react-phone-input-2/lib/style.css";
import { Col, Nav, Row, Tab, Form, Button,Modal } from "react-bootstrap";

function Claim(props) {
  return (
    <>
      <div className={`${cascading1.buymodallist}`}>
        <Modal
          className="walletmodal"
          onHide={props.onDismiss}
          show={props.isOpen}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
          <Modal.Body className={`${cascading1.modalbody} pb-5`}>
            <div className="row justify-content-center">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className={`${cascading.whtclr}`}>
                    Enter Transfer Address
                  </Form.Label>
                  <Form.Control
                    className={`${cascading.inputfiled}`}
                    id="email"
                    type="email"
                    placeholder="Enter Eallet Address"
                    onChange={(e) => props.setadd(e.target.value)}
                  />
                </Form.Group>
                <div className={`text-center mt-3`}>
                  <Button
                    className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-5`}
                    onClick={() => props.claim()}
                  >
                    Claim
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Claim;
