import React, { useEffect, useState } from "react";
import cascading from "../assests/css/all.module.css";
import cascading1 from "../assests/css/settingcard.module.css";
import { Button, Modal, Form } from "react-bootstrap";
import QRcode from "react-qr-code";
import logo from "../assests/images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import toast from "react-hot-toast";
import {QRCodeSVG} from 'qrcode.react';
import {
    fetchverifyotphone,
    fetchsendotphone,
    fetchanticodeupdate,
  fetchchangepassword,
  fetchenabletwofacode,
  fetchchangeemail,
  fetchverifyemailchange,
  fetchuploadprofileimage
} from "../api/user.axios";
import { useNavigate } from "react-router";

function Settingmodal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userdata = useSelector((state) => state?.redux.userdata);
  const [process, Setprocess] = useState(false);
  const [mailstep, setMailstep] = useState(1);
  const [mobilestep, setMobilestep] = useState(1);
  const [codestep, setCodestep] = useState(1);
  const [switches, setSwitches] = useState(true);
  const [data, setData] = useState(
    `https://qrcode.tec-it.com/API/QRCode?data=${props?.qrdata?.data}`
  );
  const [file, setFile] = useState();
  const [rawimage, setrawimage] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setrawimage(e.target.files[0])
    setFile(URL.createObjectURL(e.target.files[0]));
  }
const uploadimage= async(req , res )=>{
   const id =  toast.loading('uploading...')

}

console.log("gggggggggg",props?.qrdata?.data)
useEffect(()=>{
  setData(`https://qrcode.tec-it.com/API/QRCode?data=${props?.qrdata?.data}`)
},[props?.qrdata?.data , props])

  console.log("reduxredux", userdata);
  let intpasswordformdata = {
    id: userdata?._id,
    comfirmpassword: "",
    newpassword: "",
    oldpassword: "",
  };

  let init2fa = {
    password: "",
    twofaCode: "",
    type: "google",
  };
  const [twofa, Settwofa] = useState(init2fa);
  const [passwordformdata, setpasswordformdata] = useState(intpasswordformdata);
  const [anticode, Setanticode] = useState("");
  const [password, Setpassword] = useState("");
  const [Errors, setErrors] = useState({});
  const[otp , Setotp]= useState()
 
  const [newemail, setnewemail] = useState("");

  const changetwofa = (e) => {
    setErrors({});
    const { id, value } = e.target;
    Settwofa({ ...twofa, [id]: value });
  };

  const changepassword = (e) => {
    setErrors({});
    const { id, value } = e.target;
    setpasswordformdata({ ...passwordformdata, [id]: value });
  };





  const Savepassword = async () => {
    const id = toast.loading("Changing password");
    Setprocess(true);
    try {
      const passwordSchema = yup.object({
        newpassword: yup
          .string()
          .required("New Password is required")
          .min(8, "New Password must be at least 8 characters").matches(
            /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/,
            "Password must contain at least one symbol, one number, one uppercase letter, and one lowercase letter"
          ),
        comfirmpassword: yup
          .string()
          .required("Confirm Password is required")
          .oneOf([yup.ref("newpassword"), null], "Passwords must match"),
        oldpassword: yup
          .string()
          .required("Old Password is required")
          .min(8, "Old Password must be at least 8 characters").matches(
            /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/,
            "Password must contain at least one symbol, one number, one uppercase letter, and one lowercase letter"
          ),
      });
       await passwordSchema.validate(passwordformdata, {
        abortEarly: false,
      });
    

      const response = await fetchchangepassword(passwordformdata);
      toast.dismiss(id);
      if (response?.status) {
        toast.success(response?.message);
        props.onHide();
        navigate("/settings");
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.dismiss(id);
      toast.error("validation error");
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
    } finally {
      Setprocess(false);
    }
  };

  const disconnect = ()=>{
    localStorage.clear()
    sessionStorage.clear()
    dispatch({
        type : "userdata" , 
        data : { userdata: ""}
    })
    navigate('/')
    
  }

  const updateanticode = async () => {
    const id = toast.loading("updating anticode");
    Setprocess(true);
    try {
      const schema = yup.object().shape({
        id: yup.string().required(),
        email: yup.string().email().required(),
        password: yup.string().required(),
        anti: yup.string().required(),
      });

      let payload = {
        id: userdata._id,
        email: userdata.email,
        password: password,
        anti: anticode,
      };
      await schema.validate(payload, { abortEarly: false });
      const response = await fetchanticodeupdate(payload);
      console.log("fetchanticodeupdate", response);
      toast.dismiss(id);
      if (response?.status) {
        toast.success(response?.message);
        navigate("/settings");
        props.onHide();
      } else {
        toast.error(response?.message);
      }
      
    } catch (error) {
      toast.dismiss(id);
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
    } finally {
      Setprocess(false);
    }
  };

  const saveEnabletwofa = async () => {
    const id = toast.loading("Enabling 2FA");
    Setprocess(true);
    try {
      const validate2Fa = yup.object({
        password: yup
          .string()
          .required(" Password is required")
          .min(8, "New Password must be at least 8 characters"),
        twofaCode: yup
          .number("code need to be numbers")
          .required("2FA code is required"),
      });

      await validate2Fa.validate(twofa, { abortEarly: false });

      const response = await fetchenabletwofacode(twofa);
      toast.dismiss(id);
      if (response?.status) {
        toast.success(response?.message);

        dispatch({
          type: "userdata",
          data: { userdata: response?.data },
        });

        navigate("/settings");
      } else {
        toast.error(response?.message);
      }
      props.onHide();
    } catch (error) {
      toast.dismiss(id);
      toast.error("validation error");
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
    } finally {
      Setprocess(false);
    }
  };

  const sendtotpchangeemail = async (data) => {
    const id = toast.loading("sending otp");
    Setprocess(true);
    try {
      let payload = {
        newemail: newemail,
        type: !data ? "phonenumber" : "email",
      };
      const schema = yup.object().shape({
        newemail: yup.string().email().required("email required"),
        type: yup.string().required(),
      });
      await schema.validate(payload, { abortEarly: false });
      console.log("payloadpayload", payload);
      const response = await fetchchangeemail(payload);
     

      toast.dismiss(id);

      if (response.status) {
        toast.success(response?.message);
        setMailstep(2);
      } else {
        if (response?.message) {
          toast.error(response?.message);
        }
      }
    } catch (error) {
      toast.dismiss(id);
      toast.error("validation error");
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
    } finally {
        Setprocess(false);
    }
  };

  const veriftyemailchange = async()=>{
    const id = toast.loading('verifying otp')
    Setprocess(true);
    try{
        const payload ={
            newemail : newemail , 
            type: !data ? "phonenumber" : "email",
            otp : otp
        }
        const schema = yup.object().shape({
            newemail: yup.string().email().required("email required"),
            type: yup.string().required(),
            otp: yup.string().min(6, "OTP must be at least 6 characters long").required("OTP required"),
        });

        await schema.validate(payload , {abortEarly: false})
        

const response = await fetchverifyemailchange(payload)

        toast.dismiss(id);

        if (response.status) {
          toast.success(response?.message);

          setMailstep(2);
          props.onHide();
          disconnect()
          // navigate("/settings");
        } else {
          if (response?.message) {
            toast.error(response?.message);
          }
        }

    }catch(error){
        toast.dismiss(id);
        toast.error("validation error");
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);

    }finally{

        Setprocess(false);
    }
  

  }

  const sendotpphone =async()=>{
    const id = toast.loading('otp sending to phonenumber')
    const res = await fetchsendotphone()
    

toast.dismiss(id)
    if(res.status){
        toast.success(res.message)
        setMobilestep(2)
    }
  }
  const verifyotpphone =async()=>{
    Setprocess(true);
    const id = toast.loading('verify otp')

    try{

        const payload = {
            otp : otp 
        } 
        
        const schema = yup.object().shape({
            otp: yup.string().min(6, "OTP must be at least 6 characters long").required("OTP required"),
        });

        await schema.validate(payload , {abortEarly : false})
        
            const res = await fetchverifyotphone(payload)
          
            toast.dismiss(id)
            if(res.status){
                toast.success(res.message)
                setMobilestep(2)
                props.onHide()
            }else{
                toast.error(res.message)   
            }
          }catch(error){

            toast.dismiss(id);
            toast.error("validation error");
            const newErrors = {};
            error.inner.forEach((err) => {
              newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
          }finally{
            Setprocess(false);
          }
    
  }
const imageupload= async()=>{

  // file
  // const formData = new FormData();
  // formData.append('file', rawimage);
// console.log("jjjjjjjfff" , formData)
let data ={
  profileimage : rawimage
}

const res = await fetchuploadprofileimage(data)

if(res.status){

  toast.success(res.message)
  props.onHide()
}
console.log("jjjjjjj" , res)
}

const [imgloading, seimgtLoading] = useState(true);
const [imgerror, setimgError] = useState(false);
const handleLoad = () => {
  seimgtLoading(false);
};

const imghandleError = () => {

  setData(`https://chart.googleapis.com/chart?chs=130x130&chld=L|0&cht=qr&chl=${props?.qrdata?.data}`)
  seimgtLoading(false);
  setimgError(true);
};

  return (
    <>
      <div className={`${cascading1.buymodallist}`}>
        <Modal
          className="walletmodal"
          onHide={props.onHide}
          show={props.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onClick={props.onHide}></Modal.Header>
          <Modal.Body className={`${cascading1.modalbody} px-4 pb-5`}>
            {props.show == "Add Profile" && (
              <>
                <div className="text-center mb-3">
                  <label className="mb-3">Profile Picture</label>

                  <img
                    src={file ? file : logo}
                    className={`${cascading1.uploadimg} px-2`}
                  />
                  {/* <img src={logo} className={`${cascading1.uploadimg}`} /> */}

                  <div className={`${cascading1.profimages} mt-3`}>
                    <input
                      style={{ color: "#fff" }}
                      type="file"
                      onChange={handleChange}
                    />
                    <Button
                      className={`${cascading.whtclr} ${cascading.themebtn} ${cascading.ups}`}
                    >
                      select Image
                    </Button>
                  </div>
                </div>
                <div className="text-center pt-4">
                  <Button
                    className={`${cascading.whtclr} ${cascading.themebtn}`}
                    onClick={imageupload}
                  >
                    save image
                  </Button>
                </div>
              </>
            )}

            {props.show == "Change Email" && (
              <>
                <div className={mailstep == 1 ? "step1" : "d-none"}>
                  {switches ? (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className={`${cascading.whtclr}`}>
                      Enter New email
                      </Form.Label>
                      <Form.Control
                        className={`${cascading.inputfiled}  mb-2`}
                        id="email"
                        type="email"
                        placeholder="enter new email"
                        value={newemail}
                        onChange={(e) => {
                          setnewemail(e.target.value);
                          console.log(e);
                        }}
                      />
                      {Errors?.newemail && (
                        <span class="text-danger">{Errors?.newemail}</span>
                      )}
                    </Form.Group>
                  ) : (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className={`${cascading.whtclr} mb-2`}>
                        New Email
                      </Form.Label>
                      {/* <Form.Control
                                                className={`${cascading.inputfiled}`}
                                                type="text"
                                                id="phonenumber"
                                                placeholder=" Mobile Number"
                                            /> */}
                      <Form.Control
                        className={`${cascading.inputfiled}  mb-2`}
                        id="email"
                        type="email"
                        placeholder="enter new email"
                        value={newemail}
                        onChange={(e) => {
                          setnewemail(e.target.value);
                          console.log(e);
                        }}
                      />
                      {Errors?.newemail && (
                        <span class="text-danger">{Errors?.newemail}</span>
                      )}
                    </Form.Group>
                  )}
                  <p className={`${cascading1.nextmob}`}>
                    Try Another way?{" "}
                    <span
                      onClick={() => setSwitches(!switches)}
                      className={`${cascading1.mobs}`}
                    >
                      {switches ? "Mobile Number" : "Mail Address"}
                    </span>
                  </p>
                  <div className="text-center mt-4">
                    <Button
                    disabled={process}
                      onClick={(e) => {
                        sendtotpchangeemail(switches);
                      }}
                      className={`${cascading.whtclr} ${cascading.themebtn}`}
                    >
                      {switches ? "Send OTP to email" : "Send OTP to Phone"}
                    </Button>
                  </div>
                </div>
                <div className={mailstep == 2 ? "step2" : "d-none"}>
                  <Form.Group className="mb-4">
                    <Form.Label
                      className={`${cascading.whtclr} text-center mb-3`}
                    >
                      Enter Your OTP
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled} mb-2`}
                      type="Number"
                      id="otp"
                      value={otp}
                      onChange={(e)=>Setotp(e.target.value)}
                      placeholder="Enter your OTP"
                    />
                    <span class="text-success">
                      {" "}
                      OTP successfully send to{" "}
                      {!switches ? userdata?.phone : userdata?.email}
                    </span>
                  </Form.Group>
                  <div className="text-center mt-4">
                    <Button
                    disabled={process}
                      onClick={() => veriftyemailchange()}
                      className={`${cascading.whtclr} ${cascading.themebtn}`}
                    >
                      verify otp{" "}
                    </Button>
                    {/* <Button onClick={() => setMailstep(3)} className={`${cascading.whtclr} ${cascading.themebtn}`}>Submit</Button> */}
                  </div>
                </div>
                {/* <div className={mailstep == 3 ? 'step3' : "d-none"}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlInput1">
                                        <Form.Label className={`${cascading.whtclr}`}>
                                            New Email Address
                                        </Form.Label>
                                        <Form.Control
                                            className={`${cascading.inputfiled}`}
                                            id="email"
                                            
                                            type="email"
                                            placeholder="New email address"
                                        />
                                    </Form.Group>
                                    <div className="text-center mt-4">
                                        <Button onClick={() => setMailstep(4)} className={`${cascading.whtclr} ${cascading.themebtn}`}>Send OTP</Button>
                                    </div>
                                </div>
                                <div className={mailstep == 4 ? 'step4' : "d-none"}>
                                    <Form.Group className="mb-4">
                                        <Form.Label className={`${cascading.whtclr} text-center mb-3`}>
                                            Enter Your OTP
                                        </Form.Label>
                                        <Form.Control
                                            className={`${cascading.inputfiled}`}
                                            type="Number"
                                            id="otp"
                                            placeholder="Enter your OTP"
                                        />
                                    </Form.Group>
                                    <div className="text-center mt-4">
                                        <Button className={`${cascading.whtclr} ${cascading.themebtn}`}>Submit</Button>
                                    </div>
                                </div> */}
              </>
            )}

            {props.show == "verify phone number" && (
              <>
                <div className={mobilestep == 1 ? "step1" : "d-none"}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={`${cascading.whtclr} mb-3`}>
                     Your  Mobile Number
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      type="text"
                      id="phonenumber"
                      value={userdata?.phone}
                      disabled={true}
                      placeholder=" Mobile Number"
                    />
                  </Form.Group>
                  <div className="text-center mt-4">
                    <Button
                      onClick={() =>sendotpphone() }
                      className={`${cascading.whtclr} ${cascading.themebtn}`}
                    >
                      Send OTP
                    </Button>
                  </div>
                </div>

                <div className={mobilestep == 2 ? "step2" : "d-none"}>
                  <Form.Group className="mb-4">
                    <Form.Label
                      className={`${cascading.whtclr} text-center mb-3`}
                    >
                      Enter Your OTP
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      type="Number"
                      id="otp"
                      onChange={(e)=>Setotp(e.target.value)}
                      placeholder="enter otp..."
                    />
                  </Form.Group>
                  <div className="text-center mt-4">
                    <Button
                    onClick={verifyotpphone}
                      className={`${cascading.whtclr} ${cascading.themebtn}`}
                    >
                      verify otp
                    </Button>
                    {Errors?.otp && <span class="text-danger" >{Errors?.otp}</span>}
                  </div>
                </div>
              </>
            )}

            {props.show == "Change Password" && (
              <>
                <Form.Group className="mb-4">
                  <Form.Label className={`${cascading.whtclr} mb-2`}>
                    Old Password
                  </Form.Label>
                  <Form.Control
                    className={`${cascading.inputfiled}`}
                    id="oldpassword"
                    type="password"
                    onChange={changepassword}
                    placeholder="Old Password"
                  ></Form.Control>
                  {Errors?.oldpassword && <span class="text-danger" >{Errors?.oldpassword}</span>}
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label className={`${cascading.whtclr} mb-2`}>
                    New Password
                  </Form.Label>
                  <Form.Control
                    className={`${cascading.inputfiled}`}
                    id="newpassword"
                    onChange={changepassword}
                    type="text"
                    placeholder="New Password"
                  ></Form.Control>
                  {Errors?.newpassword && <span class="text-danger">{Errors?.newpassword}</span>}
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label className={`${cascading.whtclr} mb-2`}>
                    Confirm Password{console.log("ErrorsErrors", Errors)}
                  </Form.Label>
                  <Form.Control
                    className={`${cascading.inputfiled}`}
                    id="comfirmpassword"
                    onChange={changepassword}
                    type="text"
                    placeholder="Confirm Password"
                  ></Form.Control>
                  {Errors?.comfirmpassword && (
                    <span class="text-danger" >{Errors?.comfirmpassword}</span>
                  )}
                </Form.Group>
                <div className="text-center pt-2">
                  <Button
                    className={`${cascading.whtclr} ${cascading.themebtn}`}
                    onClick={Savepassword}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}

            {props.show == "Enable 2FA" && (
              <>
                <div className="text-center">
                  <p className={`${cascading.whtclr} mb-3`}>Scan QR Code</p>
                  {/* <QRcode value={data} />
                   */}
                  {console.log("QRCodeSVG" , data)}
                  {/* <QRCodeSVG value={data} /> */}
                  {/* {!imgloading && !imgerror ?  */}
                  {
                    data &&
<img onLoad={handleLoad} onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src=data;
  }} src={data} className={`${cascading.qrimages} img-fluid`}  alt="image"/>
                  }
                  

                  {/* <div className={`${cascading.qrimages} d-flex align-items-center justify-content-center`}>
                  <span className={`${cascading.loaderzz} img-fluid`}></span>
                  </div> */}

                   {/* : <>loading</>} */}
                  {console.log("scanqe", data)}
                </div>
                <Form.Group className="mt-4 mb-4">
                  <Form.Label
                    className={`${cascading.whtclr} text-center mb-2`}
                  >
                    Enter 6 Digit 2FA Code
                  </Form.Label>
                  <Form.Control
                    className={`${cascading.inputfiled}`}
                    type="Number"
                    placeholder="Enter your code"
                    id="twofaCode"
                    onChange={changetwofa}
                  />
                  {Errors?.twofaCode && <span>{Errors?.twofaCode}</span>}
                </Form.Group>
                <Form.Group className="mt-4 mb-4">
                  <Form.Label
                    className={`${cascading.whtclr} text-center mb-2`}
                  >
                    Password
                  </Form.Label>
                  <Form.Control
                    className={`${cascading.inputfiled}`}
                    type="password"
                    placeholder="Enter your password"
                    id="password"
                    onChange={changetwofa}
                  />
                  {Errors?.password && <span class="text-danger">{Errors?.password}</span>}
                </Form.Group>
                <div className="text-center pt-2">
                  <Button
                    className={`${cascading.whtclr} ${cascading.themebtn}`}
                    onClick={saveEnabletwofa}
                  >
                    Enable 2FA
                  </Button>
                </div>
              </>
            )}

            {(props.show == "Add Code" || props.show == "Edit Code") && (
              <>
                <div className={codestep == 1 ? "step1" : "d-none"}>
                  <Form.Group className="mt-4 mb-4">
                    <Form.Label
                      className={`${cascading.whtclr} text-center mb-2`}
                    >
                      Anti-Phishing Code
                    </Form.Label>
                    {console.log(anticode, "anticode")}
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      type="text"
                      autoComplete="off"
                      placeholder="Enter your Anti-Phishing Code"
                      onChange={(e) => Setanticode(e.target.value)}
                      value={anticode}
                    />
                    <Form.Label
                      className={`${cascading.whtclr} text-center mb-2 mt-4`}
                    >
                      Password
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      type="password"
                      placeholder="Enter your password"
                      onChange={(e) => Setpassword(e.target.value)}
                      value={password}
                    />
                  </Form.Group>
                  <div className="text-center mt-4">
                    <Button
                      onClick={updateanticode}
                      disabled={process}
                      className={`${cascading.whtclr} ${cascading.themebtn}`}
                    >
                      {process ? "processing" : "Add code"}{" "}
                    </Button>
                  </div>
                </div>
                <div className={codestep == 2 ? "step2" : "d-none"}>
                  <Form.Group className="mb-4">
                    <Form.Label
                      className={`${cascading.whtclr} text-center mb-3`}
                    >
                      Enter Your OTP
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      type="Number"
                      id="otp"
                      placeholder="Enter your OTP"
                    />
                  </Form.Group>
                  <div className="text-center pt-2">
                    <Button
                      className={`${cascading.whtclr} ${cascading.themebtn}`}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </>
            )}

            {props.show == "View" && <></>}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Settingmodal;
