import React, { useState, useEffect } from "react";
import cascading from "../assests/css/all.module.css";
import Grid from "@mui/material/Grid";
import Sidebar from "../common/Sidebar";
import { Col, Nav, Row, Tab, Button } from "react-bootstrap";
import Mlutiarbicard from "../components/multiarbicard";
import config from "../config";
import { Encryptdata } from "../actions/encode_decode";
import { getArbitrages } from "../api/trade.axios";

function Multiarbi() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [pagesize, setpagesize] = useState(20);
  const [arbicard, setArbicard] = useState({
    bnb: { data: [], page: 1 },
    kcc: { data: [], page: 1 },
  });
  const [hideloader, Sethideloader] = useState(true);
  const [loading, setloading] = useState(false);
  const [loadpage, setpageloading] = useState(false);
  const [chain, setChain] = useState("bnb");
  useEffect(() => {
    if (arbicard[chain]?.data.length == 0 || arbicard[chain]?.page > 1) {
      getArbitage(chain);
    }
    // setInterval(() => {
    //   getArbitage(chain);
    // }, 10000);
  }, [arbicard[chain]?.page, chain]);

  const getArbitage = async (chaindata) => {
    setloading(true);

    let sendData = {
      chain: chaindata, // optional
      page: 0,
      pagesize: pagesize,
      pagenumber: arbicard[chaindata]?.page,
    };
    let res = await getArbitrages(sendData);
    console.log("logooo", res);
    let data = res.data;
    if (arbicard[chaindata]?.page === 1) {
      setArbicard({
        ...arbicard,
        ...{ [chaindata]: { ...arbicard[chaindata], ...{ data: data } } },
      });
    }
    if (arbicard[chaindata]?.page > 1) {
      setArbicard({
        ...arbicard,
        ...{
          [chaindata]: {
            ...arbicard[chaindata],
            ...{ data: [...arbicard[chaindata]?.data, ...data] },
          },
        },
      });
    }

    setpageloading(false);
    setloading(false);
    setpageloading(true);
  };

  console.log("arbicard-->", arbicard);
  //  const mystyle = {}

  const onloadmore = () => {
    let currentpage = arbicard[chain]?.page + 1;
    setArbicard({
      ...arbicard,
      ...{ [chain]: { ...arbicard[chain], ...{ page: currentpage } } },
    });
    // Setpage(currentpage)
  };

  return (
    <div className={`${cascading.innerpages}`}>
      <Grid container>
        <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
          <Sidebar />
        </Grid>

        <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
          <div className={`${cascading.innercontent} ${cascading.apipage}`}>
            <div className={`container ${cascading.custom_container}`}>
              <p>dfsdfsdfsd</p>

              <Tab.Container
                id="left-tabs-example"
                onSelect={(e) => setChain(e)}
                defaultActiveKey="bnb"
              >
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills" className="flex-row">
                      <Nav.Item>
                        <Nav.Link eventKey="bnb">Binance</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="kcc">Kucoin</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="bnb">
                        {loadpage ? (
                          <Row>
                            {arbicard[chain] &&
                              arbicard[chain]?.data.length > 0 &&
                              arbicard?.[chain].data.map((e, i) => (
                                <Col
                                  md={6}
                                  lg={4}
                                  className="mt-3 mb-3 px-1 px-sm-2"
                                >
                                  <Mlutiarbicard
                                    chain={chain}
                                    data={e}
                                    pair1={e.pair1}
                                    pair2={e.pair2}
                                    pair3={e.pair3}
                                    type={e.type.split("_")}
                                  />
                                </Col>
                              ))}

                            <div className="text-center">
                              {/* { loading ? */}
                              {hideloader && (
                                <Button
                                  className={`d-flex mx-auto text-center py-2 px-3 ${cascading.whtclr} ${cascading.themebtn}`}
                                  disabled={loading}
                                  onClick={onloadmore}
                                >
                                  {!loading ? (
                                    "Load More"
                                  ) : (
                                    <>
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      >
                                        {" "}
                                      </span>
                                      <p className="ml-3 mb-0">Loading</p>
                                    </>
                                  )}
                                </Button>
                              )}
                            </div>
                          </Row>
                        ) : (
                          <div
                            className={`${cascading.height100} d-flex justify-content-center align-items-center`}
                          >
                            <span class="loader d-flex justify-content-center"></span>
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="kcc">
                        {loadpage ? (
                          <Row>
                            {arbicard[chain] &&
                              arbicard[chain]?.data.length > 0 &&
                              arbicard?.[chain].data.map((e, i) => (
                                <Col
                                  md={6}
                                  lg={4}
                                  className="mt-3 mb-3 px-1 px-sm-2"
                                >
                                  <Mlutiarbicard
                                    chain={chain}
                                    data={e}
                                    pair1={e.pair1}
                                    pair2={e.pair2}
                                    pair3={e.pair3}
                                    type={e.type.split("_")}
                                  />
                                </Col>
                              ))}

                            <div className="text-center">
                              {/* { loading ? */}
                              {hideloader && (
                                <Button
                                  className={`d-flex mx-auto text-center py-2 px-3 ${cascading.whtclr} ${cascading.themebtn}`}
                                  disabled={loading}
                                  onClick={onloadmore}
                                >
                                  {!loading ? (
                                    "Load More"
                                  ) : (
                                    <>
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      >
                                        {" "}
                                      </span>
                                      <p className="ml-3 mb-0">Loading</p>
                                    </>
                                  )}
                                </Button>
                              )}
                            </div>
                          </Row>
                        ) : (
                          <div
                            className={`${cascading.height100} d-flex justify-content-center align-items-center`}
                          >
                            <span class="loader d-flex justify-content-center"></span>
                          </div>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Multiarbi;
