import config from '../config'
import { axiosFunc } from '../actions/common'
import { Decryptdata , Encryptdata } from '../actions/encode_decode'
// const token = sessionStorage.getItem('usertoken') ? sessionStorage.getItem('usertoken') : localStorage.getItem('usertoken')
// const sees = sessionStorage.getItem('usertoken')

export const fetchcmslist = async(data)=>{

    try{
        let  senddata = {
            method :"GET",
             url: `${config.ADMIN_URL}/cmslist`,
        }
        let response =  await axiosFunc(senddata)
        response.data = await Decryptdata(response?.data)
        console.log("errxxv",response.data)
        return response.data
    }catch(err){
        console.log("erssssssssssssr",err)
        return false
    }


}

export const fetchuploadcontactus = async(data)=>{
    try{
        let  senddata = {
            method :"post",
             url: `${config.ADMIN_URL}/contactus`,
             data : { data : Encryptdata(data)},
             headers: {
                contentype: "application/json",
              },
        }
        let response =  await axiosFunc(senddata)
        response.data = await Decryptdata(response?.data)
        console.log("errxxv",response.data)
        return response.data
    }catch(err){
        const catchdata = {status : false , message : "please try again "} 

        return  catchdata  

    }
}