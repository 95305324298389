import React, { useState, useEffect } from 'react'
import cascading from '../assests/css/sidebar.module.css'
import Accordion from 'react-bootstrap/Accordion';
import logo from '../assests/images/logo.png'
import { MdMenu } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { NavLink, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import userc from "../assests/images/userc.png"
import { useSelector  , useDispatch} from 'react-redux';
import { fetchreconnect } from '../api/user.axios';
import toast  from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import config from '../config'
import profileicon from '../assests/images/profileicon.png';
import Logoutmodal from './logoutmodal';

function Sidebar() {
  const navigate = useNavigate()
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const userdata = useSelector( state=> state?.redux.userdata)

const dispatch = useDispatch()

useEffect(()=>{
  const token = localStorage.getItem('usertoken') ?  localStorage.getItem('usertoken') : sessionStorage.getItem('usertoken')
  if(token){
    fetchdata()

  }
},[])


async function fetchdata(){
  const res = await fetchreconnect()
 console.log("resssssssssss",res?.status)
if(res?.status){
  dispatch({
      type : "userdata" , 
      data : { userdata: res?.data}
  })
}else{

  if(res?.message){
// toast.error(res?.message)
dispatch({
  type : "userdata" , 
  data : { userdata: ""}
})
navigate('/')
// disconnect()


}

}
}




const disconnect = ()=>{
  localStorage.clear()
  sessionStorage.clear()
  dispatch({
      type : "userdata" , 
      data : { userdata: ""}
  })
  navigate('/')
  
}

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  const [mbmenu, setMbmenu] = useState(false)
  const [sidemenu, setSidemenu] = useState([
    {
      name: 'Arbitrages',
      path: "/arbitrages",
      img: require('../assests/images/arbitrages.png'),
      subMenus: [
        { name: 'Multi-Arbi', path: '/multiarbi' },
        // { name: 'Bot', path: "/bot", },
        { name: 'History', path: "/history", }
      ]
    },
    {
      name: 'Ticker',
      path: "/ticker",
      img: require('../assests/images/ticker.png'),
      // subMenus: []
    },
    {
      name: 'Orderbook', path: "/orderbook",
      img: require('../assests/images/orderbook.png'),
    },
    {
      name: 'Profile',
      path: "/profile",
      img: require( '../assests/images/pf.png'),
      subMenus: [
        { name: 'Settings', path: '/settings' },
        { name: 'Refferal List', path: "/refferallist", },
      ]
    },

    {
      name: 'API', path: "/api",
      img: require('../assests/images/api.png'),
    },
    {
      name: 'Logout', path: "/login",
      img: require('../assests/images/logout.png'),
    },
    

  ])
  const [logshow, setLogshow] = useState(false)

  return (
    <>
    {logshow && <Logoutmodal onDismiss={() => setLogshow(false)} />}
      <div className={`d-block d-xl-none ${cascading.menubar}`}>
        <MdMenu onClick={() => setMbmenu(true)} />
        <div className='text-end'>
        <Link to="/"><img src={logo} alt='logo' className={`img-fluid ${cascading.logoss}`} /></Link>
        </div>
      </div>
      <div className={windowWidth > 1199 ? `${cascading.sidebarsec}` : mbmenu ? `${cascading.sidebarsec} ${cascading.mobilemenu} ${cascading.mobilemenuopen}` : `${cascading.sidebarsec} ${cascading.mobilemenu}`}>
        <div className={`d-block d-xl-none ${cascading.closeicons}`}>
          <IoClose onClick={() => setMbmenu(false)} />
        </div>
        <div className={`${cascading.siderow}`}>
          <div className={`${cascading.logosec}`}> 
            <Link to="/">
            
            <img src={logo} alt='logo' className={`img-fluid ${cascading.logoimg}`} />
            </Link>
          </div>
          
          <p className={`${cascading.whtclr} d-flex align-items-center px-3 mb-0`}>
            
            {/* <img src={userc} alt='logo' className={`img-fluid me-2 ${cascading.userc}`} />  */}
            
     <img className={`img-fluid me-2 ${cascading.userc} rounded-circle`}src={ userdata?.profile ? `${config.IMG_URL}/profile/${userdata?.userid +"/"+userdata?.profile}`:userc} alt='images' />
            
            <span className={`${cascading.breaks}`}>{userdata?.email}</span></p>
          <div className={`${cascading.menulist}`}>
            <Accordion defaultActiveKey="0" >
              {sidemenu.map((e, i) => (

                <Accordion.Item eventKey={i}>
                  {e.subMenus ? <>
                    <Accordion.Header className={e.subMenus.map((e)=>e.path).includes(location.pathname) ? `${cascading.activemenus}` : ''}>
                      <div className={`${cascading.iconrow}`}>
                        <img src={e.img} alt='icon' className={`img-fluid`} />
                        <p className={`${cascading.headname}`}>{e.name}</p>
                      </div>


                    </Accordion.Header>
                    <Accordion.Body>
                      <ul className={`${cascading.ulstyle}`}>
                        {e.subMenus.map((sub) => (
                          <li>
                            {sub.name == "Logout" ?
                             <NavLink className={location.pathname == sub.path ? `${cascading.activemenus}` : ''} onClick={()=> {setLogshow(true)}} >{sub.name }</NavLink> : 
                            <NavLink className={location.pathname == sub.path ? `${cascading.activemenus}` : ''} to={sub.path} >{sub.name}</NavLink>}
                          </li>
                        ))}
                      </ul>

                    </Accordion.Body>

                  </> :


                    <Accordion.Header className={location.pathname == e.path ? `${cascading.activemenus} ${cascading.normalacc}` : `${cascading.normalacc}`}  >
                      <div className={`${cascading.iconrow}`}>
                        <img src={e.img} alt='icon' className={`img-fluid`} />
                        {e.name == "Logout" ?
                             <NavLink  onClick={()=> {setLogshow(true)}} >{e.name }</NavLink> :
                        <NavLink to={e.path} >{e.name}</NavLink>}
                      </div>
                      {console.log(location.pathname, e.path, "lcoa")}
                    </Accordion.Header>
                  }
                </Accordion.Item>
              ))}
            </Accordion>

          </div>

        </div>

      </div>
    </>

  )
}

export default Sidebar