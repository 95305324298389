import React,{useState, useEffect} from 'react'
import cascading from '../assests/css/all.module.css'
import Grid from '@mui/material/Grid';
import Sidebar from '../common/Sidebar';
import { Col, Nav, Row, Tab, Form, Button } from 'react-bootstrap';
import { Link, Router } from 'react-router-dom';
import { Forgetpasswordfetch } from '../api/user.axios';
import { toast } from 'react-hot-toast';
import { newpasswordfetch , resendotp } from '../api/user.axios';
import { newpasswordschema } from '../actions/validations/register_validation';
import { isEmpty } from '../actions/common';
import { useNavigate } from 'react-router-dom';
export function Newpassword(props) {
const navigate = useNavigate()
    const { email } = props

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);


const [Errors , setErrors] = useState("")

      const initialdata = {
    
        email : email ,
        password: "",
        confirmpassword: "",
        otp: '',
      
      };
      const [formdata, setformdata] = useState(initialdata);

      const updatedata = (e) => {
        // seterror({})
        // setformdata("");
        // setbutton(false);
        const { id, value  } = e.target;
        setErrors({})
          setformdata({ ...formdata, [id]: value });
        
      };
const submit = async()=>{
try {
    const id = toast.loading("Changing password")
   
    await newpasswordschema.validate(formdata, {abortEarly: false});
    console.log(formdata)
    let data = formdata
    let response = await newpasswordfetch(data)
    toast.dismiss(id)
    if(response?.status){
    toast.success(response?.message)
    navigate('/login')
    }else{
    toast.error(response?.message)
    }
} catch (error) {
  
    const newErrors = {};
    error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
      console.error( newErrors)
}
 
   

 
  
   

  

  



}

const  resendotpfunction = async()=>{
    const id = toast.loading("Sending OTP")
      let senddata ={
          email : email
      }
      const resendotpresponse = await resendotp(senddata)
      toast.dismiss(id)
      if(resendotpresponse?.status){
           toast.success(resendotpresponse?.message);
      }else{
          toast.success(resendotpresponse?.message);
      }
  }
  
    return (
        <div className={`${cascading.loginborder}`}>
            <div className={`container ${cascading.custom_container}`}>
                <Row>
                    <Col sm={9} md={10} xl={8} className={`mx-auto`}>
                        <div className={`${cascading.logincard}`}>
                            <h2 className={`${cascading.whtclr} mb-4`}>New Password</h2>
                            <Row>
                            <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={`${cascading.whtclr}`}>
                      Password
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      value={formdata?.password}
                      id="password"
                      type="password"
                      onChange={updatedata}
                      placeholder="Enter your Password"
                    ></Form.Control>
                     {Errors?.password && (
                    <span class="text-danger">{Errors?.password}</span>
                  )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={`${cascading.whtclr}`}>
                      Re-enter Password
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      id="confirmpassword"
                      type="password"
                      value={formdata?.confirmpassword}
                      onChange={updatedata}
                      placeholder="Re-enter Password"
                    ></Form.Control>
                  </Form.Group>
                  {Errors?.confirmpassword && (
                    <span class="text-danger">{Errors?.confirmpassword}</span>
                  )}
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className={`${cascading.whtclr}`}>
                      OTP
                    </Form.Label>
                    <Form.Control
                      className={`${cascading.inputfiled}`}
                      id="otp"
                      type="Number"
                      value={formdata?.otp}
                      onChange={updatedata}
                      placeholder="otp"
                    ></Form.Control>
                  </Form.Group>
                  {Errors?.otp && (
                    <span class="text-danger">{Errors?.otp}</span>
                  )}
                </Col>
                                <div className={`d-flex justify-content-between mt-2`}>
                                    <Link to="/login" ><Button className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-4`}>Back</Button></Link>
                                    <Button
                        className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-4`}
                        onClick={resendotpfunction}
                       
                      >
                        Resend OTP
                      </Button>
                                    <Button className={`${cascading.whtclr} ${cascading.themebtn} py-2 px-4`} onClick={submit}>Submit</Button>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    )
}

