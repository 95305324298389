import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import cascading from "../assests/css/all.module.css";
import cascading1 from "../assests/css/settingcard.module.css";
import wallet1 from "../assests/images/icon-wallet-1.svg";
import wallet2 from "../assests/images/icon-wallet-3.svg";
import wallet3 from "../assests/images/torus.svg";
import { connectWallet } from "../hook/useWallet";
import {useDispatch} from 'react-redux'
import { toast } from "react-hot-toast";
import { isEmpty } from "../actions/common";
import { initialConnectWallet } from "../hook/useWallet";

function Walletmodal(props) {
    const dispatch = useDispatch();
  const [wallet, setWallet] = React.useState([
    {
      icon: wallet1,
      beta: "Alpha",
      title: "Meta Mask",
      description:
        "MetaMask is a self-custodial wallet that lets you access blockchain applications and web3 in seconds. It supports digital tokens, NFTs, Defi, Web3 Dapps and more.",
      id: "MetaMask",
    },
    {
      icon: wallet2,
      beta: "Alpha",
      title: "Wallet Connect",
      description:
        "WalletConnect is an open-source protocol that allows your wallet to connect and interact with DApps and other wallets.",
      id: "WalletConnect",
    },
    {
      icon: wallet3,
      beta: "Alpha",
      title: "Trust Wallet",
      description:
        "Trust Wallet is a multi-chain, self-custodial wallet supporting numerous assets and blockchains, allowing users full control over their funds and easy access to DApps on iOS and Android.",
      id: "TrustWallet",
    },
  ]);

//   const initialConnectWallet = async (type) => {
//     console.log("vvaaaav", window.ethereum, window.web3);
//     const id = toast.loading(type + "Connecting");
//     var accountDetails = await connectWallet(type);
//     console.log('accountDetails-->',accountDetails)
//     if (!isEmpty(accountDetails)) {
//         dispatch({
//           type: "Account_Section",
//           data: { AccountDetails: accountDetails },
//         });
//         toast.dismiss(id)
//         toast.success('Connected Successfully')
       
      
//     } else{
//         toast.dismiss(id)
//         toast.error('Try Again')
//     }
     
//   }

 const connectwallet=async(data)=>{
  const id = toast.loading(data + "Connecting");
  if(data == "WalletConnect"){
    props.onDismiss()
  }
    let res = await initialConnectWallet(data)
    if(!isEmpty(res)){
      dispatch({
        type: "Account_Section",
        data: { AccountDetails: res },
      });
      props.onDismiss()
      toast.success('Connected Successfully',{id:id})
    }
    else{
        if(data != "WalletConnect"){
            toast.error('Try Again',{id:id})
          }
      
    }
    

}

  return (
    <>
      <div className={`${cascading1.buymodallist}`}>
        <Modal
          className="walletmodal"
          onHide={props.onDismiss}
          show={props.isOpen}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onClick={props.onDismiss}></Modal.Header>
          <Modal.Body className={`${cascading1.modalbody} pb-5`}>
            <h4 className={`${cascading.titlesss} text-center`}>
              Choose your Wallet
            </h4>
            <div className="row justify-content-center">
              {wallet.map((data, index) => {
                return (
                  <div
                    className={`${cascading1.cardfull} ${cascading1.cardss} col-sm-10 col-md-9 col-lg-6 col-xl-4 mt-4`}
                  >
                    <div
                      className={`${cascading1.settingscard}`}
                      onClick={() =>
                        connectwallet( window.ethereum ? data.id : "WalletConnect")
                       
                      }
                    >
                      <img
                        src={data.icon}
                        alt="Image"
                        width="100"
                        height="100"
                        classNameName="img-fluid"
                      />
                      <h5 className={`${cascading.whtclr} mt-2 mt-sm-3`}>
                        {data?.title}
                      </h5>
                      <p className={`${cascading.porttext} mt-2`}>
                        {data?.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Walletmodal;
