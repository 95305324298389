import { isEmpty } from "../common";
import * as yup from 'yup';

export const Registervalidation = (data)=>{
  console.log("registervalidation",data)
let error = {}
const ismobile = (mobileno) => {
    const phoneNumberRegex =  /^\+(?:[0-9] ?){6,14}[0-9]$/;
    if (!isEmpty(mobileno) && phoneNumberRegex.test(mobileno)) return true
  }

const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

if(!data?.firstname){
    error.firstname = "firstname can't be empty"; 
}

if(!data?.lastname){
    error.lastname = "lastname can't be empty"; 
}

if(data?.password != data?.repassword){
    error.repassword = "password not match";
}

if(!ismobile(data?.phonenumber)){
    error.phonenumber = "enter valid phone number";
}

if (!emailRegex.test(data?.email)) {
    error.email = "enter valid email address";
}

if(!data?.defaultcheckbox){
    error.terms = "Accept the Teams and service to create Account ";
}

return error

}



// yup.object({
//     password: yup.string().required("Password is required")
//     .min(8, "Password must be at least 8 characters")
//     .matches(
//       /[!@#$%^&*(),.?":{}|<>]/,
//       "Password must contain at least one symbol")
//     .matches(/[0-9]/, "Password must contain at least one number")
//     .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
//     .matches(/[a-z]/, "Password must contain at least one lowercase letter"),
//     confirmpassword: yup.string()
//     .oneOf([yup.ref("password")], "Passwords must match")
//     .required("Confirm password is required"),
//     otp: yup.number().required().positive().integer(),
//     email: yup.string().email('must be a valid email'),
    
//   });

export const newpasswordschema =  yup.object({
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/,
        "Password must contain at least one symbol, one number, one uppercase letter, and one lowercase letter"
      ),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Confirm password is required"),
    otp: yup.number().required("OTP is required").positive("OTP must be positive").integer("OTP must be an integer"),
    email: yup.string().email('Must be a valid email').required('Email is required'),
  });