import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Col, Nav, Row, Tab, ButtonGroup, Button, InputGroup, Form, Table } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import cascading from '../assests/css/all.module.css';



export default function Ontradetable(props) {
    const { tradedata,cancel,tab} = props
    
    const rows = [
    ];

    for (let i = 0; i < tradedata?.length; i++) {
        console.log("tradedata-->", tradedata, JSON.stringify(tradedata?.[i]?.trade,null,2))
        let btname = tradedata[i]?.type.split("_")
        let data = {
            id: i + 1,
            pair: tradedata[i]?.pair,
            trade1: btname[0],
            trade2: btname[1],
            trade3: btname[2],
            exchange: tradedata[i]?.exchange,
            revenue: tradedata[i]?.revenue,
            pending : tradedata[i]?.trade?.filter( val=> val.status != "CANCELED" && val?.status != 'FILLED' )?.map(val=>val.pair).join(','),
            filled : tradedata[i]?.trade?.filter( val => val.status == "FILLED").map(val=>val.pair).join(','),
            cancel : tradedata[i]?.trade[tradedata[i]?.trade.length -1],
            cancelled : tradedata[i]?.trade?.filter( val => val.status == "CANCELED").map(val=>val.pair),
            datas : tradedata[i].trade,
            chain : tradedata[i]?.chain
        }

        console.log("kkkk", tradedata[i]?.trade)

        rows.push(data)

    }

    console.log("rowsss", rows)

    return (
        <>
            <div
            // style={{ height: 400, width: '100%' }}
            >

                <div className='alltables'>
                    <Table responsive>
                        <thead>
                            <th>Pair</th>
                            <th>Trade</th>
                            <th>Exchange</th>
                            {tab == 'ontrade' && <th>Pending</th>}
                            {tab ==  'canceled' && <th>Cancelled</th>}
                            <th>Filled</th>
                            {tab == 'canceled' && <th>Remaining Currency</th>}
                            {tab == 'canceled' && <th>Remaining</th>}
                            {tab == 'ontrade' && <th>Option</th>}
                        </thead>
                        {console.log("asdasgd", rows)}
                      
                        <tbody>
                        {rows.length > 0 ?
                            <> {rows.map((data) =>
                             <tr>
                                <td>{data.pair}</td>
                                <td>
                                    <div>
                                        <Button className={`${data.trade1 === "BUY" ? cascading.btnoutline : cascading.btnredoutline} mt-2 mt-lg-0 me-2`}>{data.trade1}</Button>
                                        <Button className={`${data.trade2 === "BUY" ? cascading.btnoutline : cascading.btnredoutline} mt-2 mt-lg-0 me-2`}>{data.trade2}</Button>
                                        <Button className={`${data.trade3 === "BUY" ? cascading.btnoutline : cascading.btnredoutline} mt-2 mt-lg-0 me-2`}>{data.trade3}</Button>
                                    </div>
                                </td>
                                <td>
                                    {data.chain}
                                </td>
                                {tab == 'canceled' && <td>{data?.cancelled}</td>}
                               {tab == 'ontrade' && <td>
                                    {data.pending}
                                </td>} 
                                <td>
                                    {data.filled}
                                </td>
                                {tab == 'canceled' && <td>{ data.pair.split('/')[data.datas.length - 1]}</td>}
                                {tab == 'canceled' && <td>{data?.datas[data.datas.length - 2]?.buy}</td>}
                               {tab == 'ontrade' && <td>
                                <Button className={`${cascading.btnoutline} me-2`} onClick={()=>cancel({orderId : data.cancel.orderid , symbol : data?.cancel?.pair})}>{'Cancel'}</Button>
                                </td>} 
                                {/* <td>
                                    <p className={`${cascading.greencolor}`}>   {data.revenue}    </p>
                                </td> */}
                            </tr>

                            )}
                            </>

                                : 
                                <tr key="data-empty"> 
                                <td colspan={tab=="ontrade"?  "6" : "7"}>No Data Found</td>
                                 </tr>
                                }
                                </tbody>

                    </Table>


                </div>


            </div>
        </>
    );
}